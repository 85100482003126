import React from "react";
import CustomTable, { requestTypeColumn } from "../UI/CustomTable/CustomTable";
import MainContainer from "../UI/MainContainer/MainContainer";
import TooltipForComponent from "../UI/Tooltip/TooltipForComponent";
import ModalActivateDeactivateUserRecords from "./ModalActivateDeactivateUserRecords";
import ModalReassignEmail from "./ModalReassignEmail";
import ModalModifyRequest from "../Request/MyRequestsTable/ModalModifyRequest";
import ModalModifyMultipleRequest from "../Request/MyRequestsTable/ModalModifyMultipleRequest";
import DatePicker from "react-datepicker";
import { FaRegCalendarAlt } from "react-icons/fa";
import { useState } from "react";
import { connect } from "react-redux";
import { MdEdit, MdPeople } from "react-icons/md";
import { Container, Row, Col, Button, Form, InputGroup } from "react-bootstrap";
import {
    fetchRequestDetailsById,
    changeActiveDeactiveRequest,
    updateRequest,
    updateRequestAccess,
    updateRequestForReassignPrimaryEmail,
    updatemultiplerequest,
    addStandardComment,
    getAllStandardComments,
    removeStandardComment,
    fetchRequestCount
} from "../../actions/creators/userRequests";
import CustomCall from "../../actions/CustomCall/CustomCall";
import LogsButton from "../UI/LogsButton/LogsButton";
import { REQUEST_STATUS } from "../../enums";
import CustomAlert from "../UI/CustomAlert/CustomAlert";
import "../../components/Routes/Routes.css";
import CustomButton from '@material-ui/core/Button';
import { useLocation } from "react-router-dom";
import { ROLES } from "../../enums";


const AdminUserRecords = ({
    match,
    fetchRequestCount,
    fetchRequestDetailsById,
    changeActiveDeactiveRequest,
    updateRequest,
    updateRequestAccess,
    updateRequestForReassignPrimaryEmail,
    updatemultiplerequest,
    isAdmin,
    // redux props
    loggedUser,
    getAllStandardComments
}) => {
    const [tableRef] = useState(React.createRef());
    const [selectedRows, setSelectedRows] = useState([]);

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [disableBtn, setDisableBtn] = useState(false);

    //Activate/Deactivate action button states
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [isMultipleSelection, setIsMultipleSelection] = useState(false);
    const [showDateRange, setShowDateRange] = useState(false);
    const [stdComments, setStdComments] = useState([]);
    const [
        activateInactivateRequestRow,
        setActivateInactivateRequestRow,
    ] = useState({});

    const search = useLocation().search;
    const id = new URLSearchParams(search).get('Id');
    const userId = new URLSearchParams(search).get('userId');

    const [defaultSelectedFilters] = useState(
        id !==null ?
        isAdmin
            ? null
            : {
                //12: {
                //    field: "requestUser.userId",
                //    value: [`${loggedUser.userId}`],
                //},
                3: {
                    field: "requestId",
                    value: [`${id}`],
                },
                }
            :
            isAdmin
                ? null
                : {
                    4: {
                        field: "isDeleted",
                        value: [userId != null ? `1` : ``],
                    },
                    12: {
                        field: "requestUser.userId",
                        value: [userId != null ? `${userId}` :`${loggedUser.userId}`],
                    }
                }
    );

    const [showModifyRequestModal, setShowModifyRequestModal] = useState(false);
    const [isUser, setIsUser] = useState(false);
    const [showModifyMultipleRequestModal, setshowModifyMultipleRequestModal] = useState(false);
    const [selectedRequestId, setSelectedRequestId] = useState(-1);
    const [businessAccess, setBusinessAccess] = useState(-1);
    const [selectedRequestData, setSelectedRequestData] = useState({});
    const [loadingModal, setLoadingModal] = useState(true);
    const [, setShowAlert] = useState(false);
    const [customAlert, setCustomAlert] = useState(null);
    const [filters, setFilters] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [filterExcluded, setFilterExcluded] = useState(null);
    const [selectedFilterItems, setSelectedFilterItems] = useState(
        defaultSelectedFilters || {}
    );
    const [showReassignEmailModal, setShowReassignEmailModal] = useState(false);

    const DateSelectorCustom = ({ value, onClick, show, disable }) => (
        <React.Fragment>
            <Row>
                <Col xs={12}>
                    <InputGroup>
                        <Form.Control
                            placeholder={show === 1 ? "Start Date" : "End Date"}
                            value={value}
                            onClick={onClick}
                            style={{
                                backgroundColor: `${disable ? "#c5c5c5" : "#fff"}`,
                                borderRadius: 10,
                            }}
                            disabled={disable}
                        />
                        {!disable ? (
                            <Button
                                onClick={onClick}
                                style={{
                                    marginLeft: 5,
                                    backgroundColor: "white",
                                    borderColor: "#0080ff",
                                    borderRadius: 10,
                                }}
                            >
                                <FaRegCalendarAlt size={20} style={{ color: "#0080ff" }} />
                            </Button>
                        ) : null}
                    </InputGroup>
                </Col>
            </Row>
        </React.Fragment>
    );
    const onClickGeneratePdf = () => {
        setDisableBtn(true);
        if (startDate == null || endDate == null) {
            alert('Please select the date to generate PDF.')
            setDisableBtn(false);
        }
        else if (startDate > endDate) {
            alert('start date should not be greater than end date.')
            setDisableBtn(false);
        }
        else {
            fetchRequestCount(
                startDate.toDateString(),
                endDate.toDateString(),
                setDisableBtn
            );
        }

    }

    const getUpdatedFilter = (newFilters, fieldName, lookupName) => {
        const currentFilters = filters;

        const itemsArray = Object.values(selectedFilterItems);
        const selectedFilters = itemsArray.find((x) => x.field === fieldName);

        if (
            filterExcluded === fieldName &&
            selectedFilters &&
            selectedFilters.value.length > 0
        ) {
            if (selectedFilters.value.some((x) => x.trim().length > 0)) {
                newFilters[lookupName] = currentFilters[lookupName];
            }
        }
    };

    const getTableFilters = (bodyFilter) => {
        setIsLoading(true);
        CustomCall(
            "put",
            `/api/Catalogs/Filters/`,
            bodyFilter,
            (response) => {
                const newFilters = response.data.data;
                //if (!isAdmin)
                //    newFilters.requestUserUserIds[loggedUser.userId] = loggedUser.userId;

                // --------------------------
                getUpdatedFilter(newFilters, "createdDate", "createdDates");

                getUpdatedFilter(
                    newFilters,
                    "status.description",
                    "statusDescriptions"
                );

                getUpdatedFilter(newFilters, "isDeleted", "areDeleted");

                getUpdatedFilter(
                    newFilters,
                    "agency.description",
                    "agencyDescriptions"
                );

                getUpdatedFilter(
                    newFilters,
                    "practice.description",
                    "practiceDescriptions"
                );

                getUpdatedFilter(
                    newFilters,
                    "platform.description",
                    "platformDescriptions"
                );

                getUpdatedFilter(
                    newFilters,
                    "requestUser.userId",
                    "requestUserUserIds"
                );

                getUpdatedFilter(newFilters, "requestUser.name", "requestUserNames");

                getUpdatedFilter(
                    newFilters,
                    "requestUser.lastName",
                    "requestUserLastNames"
                );

                getUpdatedFilter(newFilters, "requestUser.email", "requestUserEmails");

                getUpdatedFilter(newFilters, "email", "emails");

                getUpdatedFilter(
                    newFilters,
                    "createdByUser.name",
                    "createdByUserNames"
                );

                getUpdatedFilter(
                    newFilters,
                    "createdByUser.lastName",
                    "createdByUserLastNames"
                );

                getUpdatedFilter(
                    newFilters,
                    "createdByUser.email",
                    "createdByUserEmails"
                );

                getUpdatedFilter(newFilters, "assetName", "assetNames");

                getUpdatedFilter(
                    newFilters,
                    "clientAccount.description",
                    "clientAccountDescriptions"
                );

                getUpdatedFilter(
                    newFilters,
                    "clientAccount.assetReference",
                    "clientAccountAssetReferences"
                );

                getUpdatedFilter(
                    newFilters,
                    "client.description",
                    "clientDescriptions"
                );

                getUpdatedFilter(newFilters, "clientCode.code", "clientCodes");

                getUpdatedFilter(newFilters, "productCode", "productCodes");

                getUpdatedFilter(
                    newFilters,
                    "platformOwnerShip.description",
                    "platformOwnerShipDescriptions"
                );

                getUpdatedFilter(
                    newFilters,
                    "platformUserType.description",
                    "platformUserTypeDescriptions"
                );

                getUpdatedFilter(
                    newFilters,
                    "platformUserType.basicName",
                    "platformUserTypeBasicNames"
                );

                getUpdatedFilter(
                    newFilters,
                    "approverRejectedByUser.name",
                    "approverRejectedByUserNames"
                );

                getUpdatedFilter(
                    newFilters,
                    "approverRejectedByUser.lastName",
                    "approverRejectedByUserLastNames"
                );

                getUpdatedFilter(
                    newFilters,
                    "approverRejectedByUser.email",
                    "approverRejectedByUserEmails"
                );

                getUpdatedFilter(newFilters, "approvedDate", "approvedDates");

                getUpdatedFilter(
                    newFilters,
                    "provissionerUser.name",
                    "provissionerUserNames"
                );

                getUpdatedFilter(
                    newFilters,
                    "provissionerUser.lastName",
                    "provissionerUserLastNames"
                );

                getUpdatedFilter(
                    newFilters,
                    "provissionerUser.email",
                    "provissionerUserEmails"
                );

                getUpdatedFilter(newFilters, "provissionedDate", "provissionedDates");
                // --------------------------

                setFilters(newFilters);

                setIsLoading(false);
            },
            setCustomAlert,
            setShowAlert
        );
    };

    const columns = [
        {
            title: "Select All",
            export: false,
            cellStyle: isAdmin ? {} : { minWidth: 250 },
            render: (row) => {
                return (
                    <div style={{ marginTop: '-10px' }}>
                        {!row.isPlatformRequest ? (
                            <React.Fragment>
                                {isAdmin ? <LogsButton logId={row.requestId} /> : null}
                                {isAdmin ? null : (
                                    row.askNewRangeDate && row.isDeleted ? (
                                        <Button disabled = { true }
                                            variant="" >
                                                <TooltipForComponent
                                                text="Records with an expired length of access cannot be activated without first requesting to modify the end date of access. Click pencil to modify."
                                                    placement="top"
                                                >
                                                    <p style={{ fontWeight: "bold" }}>Activate</p>
                                                </TooltipForComponent>
                                            
                                        </Button>
                                    ):
                                    <Button
                                        variant="link"
                                        onClick={() => {
                                            setShowDateRange(row.askNewRangeDate);
                                            setIsMultipleSelection(false);
                                            onClickDeactivateLink(row);
                                            getAllStandardComments(setStdComments, 'Leavers');
                                        }}
                                    >
                                        {row.isDeleted ? (
                                            <TooltipForComponent
                                                text="Enable this user record "
                                                placement="top"
                                            >
                                                <u style={{ color: "green", fontWeight: "bold" }}>Activate</u>
                                            </TooltipForComponent>
                                        ) : (
                                                <TooltipForComponent
                                                    text="Disable this user record "
                                                    placement="top"
                                                >
                                                    <u style={{ color: "red", fontWeight: "bold" }}>Deactivate</u>
                                                </TooltipForComponent>
                                            )}
                                    </Button>
                                )}
                                {!isAdmin && row.isDeleted && row.askNewRangeDate ? (
                                    <React.Fragment>
                                        <TooltipForComponent
                                            text="Modify this user record, you can change length of access"
                                            placement="top"
                                        >
                                            <Button
                                                variant="link"
                                                onClick={() => {
                                                    setShowModifyRequestModal(true);
                                                    setIsUser(true);
                                                    setSelectedRequestId(row.requestId);
                                                    fetchRequestDetailsById(
                                                        row.requestId,
                                                        setSelectedRequestData,
                                                        setLoadingModal,
                                                        true
                                                    );
                                                }}
                                                disabled={getPencilIconDisabled()}
                                            >
                                                <MdEdit
                                                    style={{marginLeft:16}}
                                                    size={25} />
                                            </Button>
                                        </TooltipForComponent>
                                    </React.Fragment>
                                ):null}
                                {isAdmin || row.isDeleted ? null : (
                                    <React.Fragment>
                                        <TooltipForComponent
                                            text="Modify this user record, you can change some values like platform user type and length of access"
                                            placement="top"
                                        >
                                            <Button
                                                variant="link"
                                                onClick={() => {
                                                    setShowModifyRequestModal(true);
                                                    setSelectedRequestId(row.requestId);
                                                    fetchRequestDetailsById(
                                                        row.requestId,
                                                        setSelectedRequestData,
                                                        setLoadingModal,
                                                        true
                                                    );
                                                }}
                                                disabled={getPencilIconDisabled()}
                                            >
                                                <MdEdit size={25} />
                                            </Button>
                                        </TooltipForComponent>
                                        <TooltipForComponent
                                            text="Reassign a user platform email to a new user"
                                            placement="top"
                                        >
                                            <Button
                                                variant="link"
                                                onClick={() => {
                                                    setShowReassignEmailModal(true);
                                                    setSelectedRequestId(row.requestId);
                                                    fetchRequestDetailsById(
                                                        row.requestId,
                                                        setSelectedRequestData,
                                                        setLoadingModal
                                                    );
                                                }}
                                                disabled={getPencilIconDisabled()}
                                            >
                                                <MdPeople size={25} />
                                            </Button>
                                        </TooltipForComponent>
                                    </React.Fragment>
                                )}
                            </React.Fragment>
                        ) : null}
                    </div>
                );
            },
        },
        {
            field: "requestId",
            title: "Record ID",
        },
        //{
        //    field: "createdDate",
        //    title: "Request Date",
        //    lookup: filters.createdDates || {},
        //    forceRenderExport: true,
        //    render: (row) => {
        //        return new Date(row.createdDate).toLocaleDateString();
        //    },
        //},
        requestTypeColumn(!isAdmin),
        {
            field: "status.description",
            title: "Request Status",
            hidden: !isAdmin,
            lookup: filters.statusDescriptions || {},
            render(row) {
                return (
                    row.status.description == "Submitted" ? <p style={{ color: "green", fontWeight: "bold" }}>{row.status.description}</p> :
                        row.status.description == "Approved" ? <p style={{ color: "orange", fontWeight: "bold" }}>{row.status.description}</p> :
                            <p>{row.status.description}</p>
                )
            }
        },        
        {
            field: "isDeleted",
            hidden: isAdmin,
            title: "Record Status",
            forceRenderExport: true,
            render: (row) => {
                //if (row.statusId === REQUEST_STATUS.COMPLETED || REQUEST_STATUS.ARCHIVED) {
                    if (row.isDeleted) return "Inactive";
                    else return "Active";
                //    return row.isDeleted ? <p style={{ color: "red", fontWeight: "bold" }}>Inactive</p> : <p style={{ color: "green", fontWeight: "bold" }}>Active</p>;
                //}
                //if (
                //    row.statusId === REQUEST_STATUS.PENDING ||
                //    row.statusId === REQUEST_STATUS.APPROVED
                //) {
                //    return "In Progress";
                //}
                //if (row.statusId === REQUEST_STATUS.REJECTED || REQUEST_STATUS.CANCELLED) {
                //    return "No Record";
                //}
            },
            lookup: filters.areDeleted || {},
        },
        {
            field: "isInternal",
            title: "User Type",
            forceRenderExport: true,
            render: (row) => {
                if (row.isInternal === true) return "Internal";
                else return "External";
            },
            lookup: filters.areInternal || {},
        },
        //{ field: "region.description", title: "Region", filtering: false },
        //{ field: "market.description", title: "Market", filtering: false },
        {
            field: "agency.description",
            title: "Agency",
            lookup: filters.agencyDescriptions || {},
        },
        {
            field: "practice.description",
            title: "Practice",
            lookup: filters.practiceDescriptions || {},
        },
        {
            field: "platform.description",
            title: "Platform",
            width: '8%',
            cellStyle: { padding: 6, overflowWrap: 'anywhere' },
            lookup: filters.platformDescriptions || {},
        },
        {
            field: "requestUser.userId",
            title: "UAP User ID",
            cellStyle: () => ({ minWidth: 40 }),
            forceRenderExport: true,
            /*     render: (row) => {
                     if (row.userId == row.mergedUserId)
                     return row.requestUserUserIds ? "not null" : "null";
                 },*/
            render(row) {
                if (row.requestUser.mergedUserId == null)
                    return row.requestUser.userId;
                else
                    return row.requestUser.mergedUserId;

            },
            lookup: filters.requestUserUserIds || {},
        },
        {
            field: "requestUser.name",
            title: "User First Name",
            cellStyle: () => ({ minWidth: 130 }),
            lookup: filters.requestUserNames || {},
        },
        {
            field: "requestUser.lastName",
            title: "User Last Name",
            cellStyle: () => ({ minWidth: 130 }),
            lookup: filters.requestUserLastNames || {},
        },
        {
            field: "requestUser.email",
            title: "Primary User Email",
            lookup: filters.requestUserEmails || {},
        },
        {
            field: "email",
            title: "User Platform Email",
            lookup: filters.emails || {},
        },
        //{
        //    field: "createdByUser.name",
        //    title: "Requestor First Name",
        //    cellStyle: () => ({ minWidth: 200 }),
        //    lookup: filters.createdByUserNames || {},
        //},
        //{
        //    field: "createdByUser.lastName",
        //    title: "Requestor Last Name",
        //    cellStyle: () => ({ minWidth: 200 }),
        //    lookup: filters.createdByUserLastNames || {},
        //},
        
        {
        field: "client.description",
        title: "Client",
        cellStyle: () => ({ minWidth: 150 }),
        lookup: filters.clientDescriptions || {},
        },
        {
            field: "assetName",
            title: "Asset Type",
            lookup: filters.assetNames || {},
        },
        {
            field: "clientAccount.description",
            title: "Asset Name",
            cellStyle: { minWidth: 400 },
            lookup: filters.clientAccountDescriptions || {},
        },
        {
            field: "clientAccount.assetReference",
            title: "Asset ID",
            cellStyle: () => ({ minWidth: 195 }),
            lookup: filters.clientAccountAssetReferences || {},
        },
         {
            field: "createdDate",
            title: "Request Date",
            lookup: filters.createdDates || {},
            forceRenderExport: true,
            render: (row) => {
                return new Date(row.createdDate).toLocaleDateString();
            },
        },
         {
            field: "createdByUser.email",
            title: "Requestor Email",
            lookup: filters.createdByUserEmails || {},
        }, 
       
        //{
        //    field: "clientCode.code",
        //    title: "Client Code",
        //    cellStyle: () => ({ minWidth: 150 }),
        //    lookup: filters.clientCodes || {},
        //},
        //{
        //    field: "productCode",
        //    title: "Product Code",
        //    cellStyle: () => ({ minWidth: 150 }),
        //    lookup: filters.productCodes || {},
        //},
        //{
        //    field: "platformOwnerShip.description",
        //    title: "Account Ownership",
        //    cellStyle: () => ({ minWidth: 150 }),
        //    lookup: filters.platformOwnerShipDescriptions || {},
        //},
        {
            field: "platformUserType.description",
            title: "Platform User Type",
            cellStyle: { minWidth: 150 },
            lookup: filters.platformUserTypeDescriptions || {},
            render(row) {
                return (
                    row.platformUserType.basicName == "Super Administrator" || row.platformUserType.basicName == "Administrator" ? <p style={{ color: "red" }}>{row.platformUserType.description}</p> :
                        <p>{row.platformUserType.description}</p>
                )
            }
        },
        {
            field: "platformUserType.basicName",
            title: "Normalized User Type",
            cellStyle: () => ({ minWidth: 150 }),
            lookup: filters.platformUserTypeBasicNames || {},
            render(row) {
                return (
                    row.platformUserType.basicName == "Super Administrator" || row.platformUserType.basicName == "Administrator" ? <p style={{ color: "red" }}>{row.platformUserType.basicName}</p> :
                        <p>{row.platformUserType.basicName}</p>
                )
            }
        },
        {
            field: "accessDate",
            title: "Length of Access",
            cellStyle: () => ({ minWidth: 100 }),
            lookup: filters.accessDates || {},
            forceRenderExport: true,
            render: (row) => {
                return row.accessDate;
            },
        },
        //{
        //    field: "approverRejectedByUser.name",
        //    title: "Approver First Name",
        //    cellStyle: () => ({ minWidth: 200 }),
        //    lookup: filters.approverRejectedByUserNames || {},
        //},
        //{
        //    field: "approverRejectedByUser.lastName",
        //    title: "Approver Last Name",
        //    cellStyle: () => ({ minWidth: 200 }),
        //    lookup: filters.approverRejectedByUserLastNames || {},
        //},
        {
            field: "approverRejectedByUser.email",
            title: "Approver Email",
            lookup: filters.approverRejectedByUserEmails || {},
        },
        {
            field: "approvedDate",
            title: "Approval Date",
            type: "date",
            lookup: filters.approvedDates || {},
            forceRenderExport: true,
            render: (row) => {
                return row.approvedDate
                    ? new Date(row.approvedDate).toLocaleDateString()
                    : "";
            },
        },
        //{
        //    field: "provissionerUser.name",
        //    title: "Provisioner First Name",
        //    cellStyle: () => ({ minWidth: 200 }),
        //    lookup: filters.provissionerUserNames || {},
        //},
        //{
        //    field: "provissionerUser.lastName",
        //    title: "Provisioner Last Name",
        //    cellStyle: () => ({ minWidth: 200 }),
        //    lookup: filters.provissionerUserLastNames || {},
        //},
        {
            field: "provissionerUser.email",
            title: "Provisioner Email",
            lookup: filters.provissionerUserEmails || {},
        },
        {
            field: "provissionedDate",
            title: "Provisioning Date",
            type: "date",
            lookup: filters.provissionedDates || {},
            forceRenderExport: true,
            render: (row) => {
                return row.provissionedDate
                    ? new Date(row.provissionedDate).toLocaleDateString()
                    : "";
            },
        },
    ];

    const resolveFunction = (resolve, response) => {
        if (response.succeeded) {
            resolve({
                data: response.data.requests,
                page: response.data.page,
                totalCount: response.data.totalCount,
            });
        } else {
            alert(response.message);
        }
    };

    const urlBuilder = () => "/api/Request/UserRecords";
    
    const getBodyData = (query, selectedFilterItems) => {
        const data = {};

        data.orderBy = query.orderBy === undefined ? isAdmin ? "createdDate" : "platform.description" : query.orderBy.field;
        data.orderAsc = (query.orderDirection || "desc") === "asc" ? true : isAdmin ? false: true;
        data.page = query.page;
        data.pageSize = query.pageSize;
        data.justCompleted = !isAdmin;
        data.initialLoad = isAdmin;
        data.filters = {};
        data.searchCriteria = query.search || "";

        Object.keys(selectedFilterItems).forEach(function (key) {
            data.filters[selectedFilterItems[key].field] =
                selectedFilterItems[key].value;
        });
        
        getTableFilters(data);

        return data;
    };



    // Event for the table multiselection
    const onSelectionChange = (rows) => {
        setSelectedRows(rows);
        getMultipleEditButtonDisabled(rows);
    };

    // function to enable/disable the buttons to Activate/Deactivate
    const getDisabled = (criteria) => {
        if (selectedRows.length === 0) return true;
        const result = selectedRows.filter((row) => row.isDeleted !== criteria);
        return result.length > 0 ? true : false;
    };

    const getPencilIconDisabled = () => {
        if (selectedRows.length > 1) {
            return true;
        }
    }

    const getMultipleEditButtonDisabled = (rows) => {
        if (rows.length === 0) {
            return true;
        }
        else {

            let prevPlatformUserTypeId = 0;
            let prevRegionId = 0;
            let prevMarketId = 0;
            let prevAgencyId = 0;
            let prevPracticeId = 0;
            let prevAssetId = 0;
            let prevPlatformId = 0;
            let prevplatformemail = "";
            let count = 0;
            let allsamecount = 0;
            if (rows.length > 1) {
                for (let i = 0; i < rows.length; i++) {
                    if (count == 0) {
                        if (prevPlatformUserTypeId === 0 && prevRegionId === 0 && prevMarketId === 0 && prevAgencyId === 0
                            && prevPracticeId === 0 && prevAssetId === 0 && prevPlatformId === 0
                            //&& prevplatformemail === ""
                            && !rows[i].isDeleted) {
                            prevPlatformUserTypeId = rows[i].platformUserTypeId;
                            prevRegionId = rows[i].regionId;
                            prevMarketId = rows[i].marketId;
                            prevAgencyId = rows[i].agencyId;
                            prevPracticeId = rows[i].practiceId;
                            prevAssetId = rows[i].assetId;
                            prevPlatformId = rows[i].platformId;
                            //prevplatformemail = rows[i].email;
                            allsamecount++;
                        }
                    }
                    else {
                        if (prevPlatformUserTypeId === rows[i].platformUserTypeId && prevRegionId === rows[i].regionId && prevMarketId === rows[i].marketId && prevAgencyId === rows[i].agencyId
                            && prevPracticeId === rows[i].practiceId && prevAssetId === rows[i].assetId && prevPlatformId === rows[i].platformId
                            //&& prevplatformemail === rows[i].email
                            && !rows[i].isDeleted) {
                            prevPlatformUserTypeId = rows[i].platformUserTypeId;
                            prevRegionId = rows[i].regionId;
                            prevMarketId = rows[i].marketId;
                            prevAgencyId = rows[i].agencyId;
                            prevPracticeId = rows[i].practiceId;
                            prevAssetId = rows[i].assetId;
                            prevPlatformId = rows[i].platformId;
                            //prevplatformemail = rows[i].email;
                            allsamecount++;
                        }
                    }
                    count++;
                }
                if (allsamecount === rows.length) {
                    return false;

                }
                else {
                    return true;
                }
            }
            else {
                return true;
            }
        }

    };

    const onClickActivate = () => {
        const checkIfAskNewRangeDate = selectedRows.filter(
            (row) => row.askNewRangeDate
        );
        const checkIfAreTheSameAccessDate = selectedRows.filter(
            (row) => row.isAccessDate
        );

        if (
            checkIfAskNewRangeDate.length > 0 ||
            checkIfAreTheSameAccessDate.length > 0
        ) {
            if (
                checkIfAreTheSameAccessDate.length !== 0 &&
                checkIfAreTheSameAccessDate.length !== selectedRows.length
            ) {
                setCustomAlert(
                    <CustomAlert
                        show={true}
                        setShow={setShowAlert}
                        title={""}
                        message={
                            "You cannot mix records to activate with both unlimited and specific lengths of access associated with them.  Please select all of one type in order to proceed to request to activate."
                        }
                    />
                );
            } else if (
                checkIfAskNewRangeDate.length !== 0 &&
                checkIfAskNewRangeDate.length !== selectedRows.length
            ) {
                setCustomAlert(
                    <CustomAlert
                        show={true}
                        setShow={setShowAlert}
                        title={""}
                        message={
                            "Some of the records you have chosen to activate have expired lengths of access. Records which have expired will need to be selected separately from the unexpired records to allow you to set a new length of access and proceed to activate."
                        }
                    />
                );
            } else {
                if (checkIfAskNewRangeDate.length === selectedRows.length) {
                    setShowDateRange(true);
                }
                setIsMultipleSelection(true);
                setShowConfirmationModal(true);
            }
        } else {
            setActivateInactivateRequestRow({
                ...activateInactivateRequestRow,
                isDeleted: true,
            });
            setShowDateRange(false);
            setIsMultipleSelection(true);
            setShowConfirmationModal(true);
        }
    };

    const onClickDeactivate = () => {
       
        var responsemessage;
        var userids = [];
        
        for (let i = 0; i < selectedRows.length; i++) {
            userids.push(selectedRows[i].userId);
        }

        CustomCall(
            "post",
            `/api/Request/CheckMergedProfile/`,
            userids,
            (response) => {
                responsemessage = response.data.errorMessage;
                if (responsemessage === "") {
                    setActivateInactivateRequestRow({
                        ...activateInactivateRequestRow,
                        isDeleted: false,
                    });
                    setShowDateRange(false);
                    setIsMultipleSelection(true);
                    setShowConfirmationModal(true);
                }
                else {

                    var text = "ALERT: The records you are requesting to deactivate are attached" + '\n' +
                        "to a user who has two profiles " + responsemessage + '\n' +

                        "If you are looking to deactivate all records for this user,hit Cancel and go back to the Modify page to select all records for " + '\n' +
                        responsemessage + '\n' + '\n' +

                        "If you want to proceed with the request as- is,hit OK to proceed with your request to deactivate."

                    var confirmBox = window.confirm(text)
                    if (confirmBox == true) {
                        setActivateInactivateRequestRow({
                            ...activateInactivateRequestRow,
                            isDeleted: false,
                        });
                        setShowDateRange(false);
                        setIsMultipleSelection(true);
                        setShowConfirmationModal(true);
                    }
                }
            },
            setCustomAlert,
            setShowAlert
        )
    };

    const onClickDeactivateLink = (row) => {
        var responsemessage;
        var userids = [];
        userids.push(row.userId);
        CustomCall(
            "post",
            `/api/Request/CheckMergedProfile/`,
            userids,
            (response) => {
                responsemessage = response.data.errorMessage;
                if (responsemessage === "") {

                    setActivateInactivateRequestRow(row);
                    setShowDateRange(false);
                    setIsMultipleSelection(false);
                        setShowConfirmationModal(true);
                }
                else {

                    var text = "ALERT: The records you are requesting to deactivate are attached" + '\n' +
                        "to a user who has two profiles " + responsemessage + '\n' +

                        "If you are looking to deactivate all records for this user, hit Cancel and go back to the Modify page to select all records for " + '\n' +
                        responsemessage + '\n' + '\n' +

                        "If you want to proceed with the request as- is, hit OK to proceed with your request to deactivate."

                    var confirmBox = window.confirm(text)
                    if (confirmBox == true) {
                        setActivateInactivateRequestRow(row);
                        setShowDateRange(false);
                        setIsMultipleSelection(false);
                        setShowConfirmationModal(true);
                    }
                }
            },
            setCustomAlert,
            setShowAlert
        )
    };

    const onComplete = () => {
        setSelectedFilterItems(defaultSelectedFilters || {});
        tableRef.current.onQueryChange();
    };

    let box = {
        display: 'inline-block'
    };

    return (
        <MainContainer>
            {customAlert}
            <ModalModifyRequest
                showModal={showModifyRequestModal}
                isUser={isUser}
                setShowModal={setShowModifyRequestModal}
                requestId={selectedRequestId}
                requestData={selectedRequestData}
                loadingModal={loadingModal}
                setLoadingModal={setLoadingModal}
                updateRequest={updateRequest}
                updateRequestAccess={updateRequestAccess}
                BusinessAccess={setBusinessAccess}
                isEditable={true}
                fetchData={() => {
                    setSelectedFilterItems(defaultSelectedFilters || {});
                    if (tableRef.current) {
                        tableRef.current.onQueryChange();
                    }

                }}
                showCommentBox={true}
            />
            <ModalModifyMultipleRequest
                showModal={showModifyMultipleRequestModal}
                setShowModal={setshowModifyMultipleRequestModal}
                requestData={selectedRequestData}
                selectedrequestIds={selectedRequestId}
                loadingModal={loadingModal}
                setLoadingModal={setLoadingModal}
                updatemultiplerequest={updatemultiplerequest}
                isEditable={true}
                fetchData={() => {
                    setSelectedRows([]);
                    setSelectedFilterItems(defaultSelectedFilters || {});
                    if (tableRef.current) {
                        tableRef.current.onQueryChange();
                    }

                }}
                showCommentBox={true}
            />
            <ModalReassignEmail
                showModal={showReassignEmailModal}
                setShowModal={setShowReassignEmailModal}
                requestId={selectedRequestId}
                requestData={selectedRequestData}
                loadingModal={loadingModal}
                setLoadingModal={setLoadingModal}
                updateRequestForReassignPrimaryEmail={updateRequestForReassignPrimaryEmail}
                isEditable={true}
                fetchData={() => {
                    setSelectedFilterItems(defaultSelectedFilters || {});
                    if (tableRef.current) {
                        tableRef.current.onQueryChange();
                    }
                }}
            />
            <ModalActivateDeactivateUserRecords
                showModal={showConfirmationModal}
                setShowModal={setShowConfirmationModal}
                showDateRange={showDateRange}
                title={
                    isMultipleSelection
                        ? `${selectedRows.length > 0 && selectedRows[0].isDeleted
                            ? "Activate"
                            : "Deactivate"
                        } User Records`
                        : `${activateInactivateRequestRow.isDeleted
                            ? "Activate"
                            : "Deactivate"
                        } User Record`
                }
                body={
                    isMultipleSelection
                        ? `${selectedRows.length > 0 && selectedRows[0].isDeleted
                            ? "Are you sure you want to activate these user records?"
                            : "Are you sure you want to deactivate these user records?"
                        }`
                        : `${activateInactivateRequestRow.isDeleted
                            ? "Are you sure you want to reactivate this user record?"
                            : "Are you sure you want to deactivate this user record?"
                        }`


                }
                confirmationFunction={(setLoading, setShowModal, body) => {
                    if (isMultipleSelection) {
                        changeActiveDeactiveRequest(
                            false,
                            !selectedRows[0].isDeleted,
                            selectedRows,
                            onComplete,
                            setLoading,
                            setShowModal,
                            setSelectedRows,
                            body
                        );
                    } else {
                        changeActiveDeactiveRequest(
                            true,
                            !activateInactivateRequestRow.isDeleted,
                            activateInactivateRequestRow,
                            onComplete,
                            setLoading,
                            setShowModal,
                            setSelectedRows,
                            body
                        );
                    }
                }}
                StandardComments={stdComments}
                IsLeavers={false}
            />
            {/*{isAdmin ? (*/}
            {/*    <React.Fragment>*/}
            {/*        <Container fluid className="pb-2">*/}
            {/*            <Row>*/}
            {/*                <Col*/}
            {/*                    style={{ textAlign: "center", color: "#808080", transform: "translateY(17%)" }}*/}
            {/*                    xs={12} sm={4} md={4} lg={4} xl={3}*/}
            {/*                >*/}
            {/*                    Select date range for audit logs*/}
            {/*                </Col>*/}
            {/*                <Col xs={12} sm={4} md={3} lg={3} xl={2}>*/}
            {/*                    <DatePicker*/}
            {/*                        selected={*/}
            {/*                            startDate !== null*/}
            {/*                                ? startDate*/}
            {/*                                :  null*/}
            {/*                        }*/}
            {/*                        onChange={(date) => {*/}
            {/*                            setStartDate(date);*/}
            {/*                            setEndDate(null);*/}
            {/*                        }}*/}
            {/*                        selectsStart*/}
            {/*                        customInput={*/}
            {/*                            <DateSelectorCustom show={1} disable={false} />*/}
            {/*                        }*/}
            {/*                    />*/}
            {/*                </Col>*/}
            {/*                <Col xs={12} sm={4} md={3} lg={3} xl={2}>*/}
            {/*                    <DatePicker*/}
            {/*                        selected={*/}
            {/*                            endDate !== null*/}
            {/*                                ? endDate*/}
            {/*                                :  null*/}
            {/*                        }*/}
            {/*                        onChange={(date) => setEndDate(date)}*/}
            {/*                        selectsEnd*/}
            {/*                        minDate={*/}
            {/*                            startDate !== null*/}
            {/*                                ? startDate*/}
            {/*                                :  null*/}
            {/*                        }*/}
            {/*                        customInput={*/}
            {/*                            <DateSelectorCustom show={2} disable={false} />*/}
            {/*                        }*/}
            {/*                    />*/}
            {/*                </Col>*/}
            {/*                <Col xs={12} sm={4} md={3} lg={3} xl={2}>*/}
            {/*                    */}{/*<Button disabled={disableBtn}*/}
            {/*                    */}{/*    className="ml-2"*/}
            {/*                    */}{/*    variant="success"*/}
            {/*                    */}{/*    onClick={onClickGeneratePdf}*/}
            {/*                    */}{/*>*/}
            {/*                    */}{/*    Generate PDF*/}
            {/*                    */}{/*</Button>*/}

            {/*                    <CustomButton*/}
            {/*                        disabled={disableBtn}*/}
            {/*                        variant="contained" color="secondary"*/}
            {/*                    onClick={onClickGeneratePdf}*/}
            {/*                    >*/}
            {/*                    Generate PDF*/}
            {/*                    </CustomButton>*/}
            {/*                </Col>*/}
            {/*            </Row>*/}
            {/*        </Container>*/}
            {/*    </React.Fragment>*/}
            {/*) : null}*/}
            {isAdmin ? null : (
                <Container fluid className="pb-2">
                    <Row>
                        <Col xs={12} md={6}>
                            <CustomButton
                                disabled={getDisabled(true)}
                                variant="contained" color="secondary"
                                onClick={onClickActivate}
                            >
                                Activate
              </CustomButton>
                            <CustomButton disabled={getDisabled(false)} onClick={onClickDeactivate} variant="contained" color="secondary">
                                Deactivate
              </CustomButton>

                            <TooltipForComponent
                                text="All Access must follow the same path
                                (Region-Market-Agency-Practice-Platform-AssetType-Platform User Type)"
                                placement="right"
                            >
                                <span>
                                    <CustomButton
                                        disabled={getMultipleEditButtonDisabled(selectedRows)}
                                        onClick={() => {
                                            setshowModifyMultipleRequestModal(true);
                                            setSelectedRequestId(selectedRows);
                                            fetchRequestDetailsById(
                                                selectedRows[0].requestId,
                                                setSelectedRequestData,
                                                setLoadingModal,
                                                true
                                            );
                                        }}
                                        variant="contained" color="secondary"
                                    >
                                        Change User Role for Multiple Records
                                        </CustomButton>
                                </span>
                            </TooltipForComponent>
                            <br />
                            <br />
                            <div> <b> Selected Records : ({selectedRows.length})</b></div>

                        </Col>
                        <Col
                            style={{ textAlign: "left", color: "white", backgroundColor: "gray", borderRadius:"3px" }}
                            xs={12}
                            md={6}
                        >
                            The access records shown below have been preselected to display your records only.
                            If you are looking to modify the access of someone else, press the green ‘Clear Filter’
                            button to remove the results from the table.   You can then apply a new filter to one of
                            the available columns to find the user you are looking for.
            </Col>
                    </Row>
                </Container>
            )}
            <CustomTable
                tableRef={tableRef}
                columns={columns}
                title={isAdmin ? "Request Tracker" : "Modify an Existing Access"}
                resolveFunction={resolveFunction}
                urlBuilder={urlBuilder}
                showSelectionColumn={!isAdmin}
                onSelectionChange={onSelectionChange}
                hideExportButton={loggedUser.roleId !== ROLES.STANDARD ? false:true}
                getBodyData={getBodyData}
                showSearchBar={true}
                fillHeight={true}
                paddingTop={isAdmin ? 200 : 250}
                methodType="PUT"
                isLoading={isLoading}
                defaultSelectedFilters={defaultSelectedFilters}
                selectedFilterItems={selectedFilterItems}
                setSelectedFilterItems={setSelectedFilterItems}
                setFilterExcluded={setFilterExcluded}
                headerColor={isAdmin ? "rgb(0 181 177)" : "rgb(10 39 86)"}
            />
        </MainContainer>
    );
};

const mapStateToProps = (state) => ({
    loggedUser: state.request.loggedUser,
});

export default connect(mapStateToProps, {
    fetchRequestCount,
    fetchRequestDetailsById,
    changeActiveDeactiveRequest,
    updateRequest,
    updateRequestAccess,
    updateRequestForReassignPrimaryEmail, getAllStandardComments,
    updatemultiplerequest
})(AdminUserRecords);
