import React, { useState } from "react";
import { connect } from "react-redux";
import { Col, Row, Spinner } from "react-bootstrap";
import EllipsisText from "react-ellipsis-text";
import UserSecondaryLogButton from "../UI/LogsButton/UserSecondaryLogButton";

import CustomTable, {
    filterArrayToUniqueKeyValueObject,
} from "../UI/CustomTable/CustomTable";
import LogUserAditionalEmailTable from "./LogUserAditionalEmailTable";


const AllUsersLogTable = ({
    //Props Redux
    fetchedData,
}) => {
    const [loading, setLoading] = useState(false);
    //  const [fetchedData, fetchAllUsersList] = useState(false);
    const [customAlert, setCustomAlert] = useState(null);
    //const [, setShowAlert] = useState(false);

    //Filter filters helpers
    const [newFilters, setNewFilters] = useState(false); //Know if any filter is applied.
    const [newDataFilters, setNewDataFilters] = useState([]); //Data after filter.
    const [newDataData, setNewDataData] = useState([]); //Filters after filter.
    const [selectedFilterItems, setSelectedFilterItems] = useState({});


    const columns = [
        {
            field: "serialNo",
            title: "Serial No",
            lookup: filterArrayToUniqueKeyValueObject(
                newFilters ? newDataFilters : fetchedData,
                "serialNo"
            ),
        },
        {
            field: "userId",
            title: "UAP Id",
            lookup: filterArrayToUniqueKeyValueObject(
                newFilters ? newDataFilters : fetchedData,
                "userId"
            ),
        },
        {
            field: "oldName",
            title: "Old First Name",
            lookup: filterArrayToUniqueKeyValueObject(
                newFilters ? newDataFilters : fetchedData,
                "oldName"
            ),
            render(row) {
                if (row.oldName !== row.newName) {
                    return (
                        <span className="highlight">
                            {row.oldName}
                        </span>
                    );
                }
                else {
                    return (
                        <span>
                            {row.oldName}
                        </span>
                    );
                }

            }
        },
        {
            field: "newName",
            title: "New First Name",
            lookup: filterArrayToUniqueKeyValueObject(
                newFilters ? newDataFilters : fetchedData,
                "newName"
            ),
            render(row) {
                if (row.oldName !== row.newName) {
                    return (
                        <span className="highlight">
                            {row.newName}
                        </span>
                    );
                }
                else {
                    return (
                        <span>
                            {row.newName}
                        </span>
                    );
                }
            }

        },
        {
            field: "oldLastName",
            title: "Old Last Name",
            lookup: filterArrayToUniqueKeyValueObject(
                newFilters ? newDataFilters : fetchedData,
                "oldLastName"
            ),
            render(row) {
                if (row.oldLastName !== row.newLastName) {
                    return (
                        <span className="highlight">
                            {row.oldLastName}
                        </span>
                    );
                }
                else {
                    return (
                        <span>
                            {row.oldLastName}
                        </span>
                    );
                }
            }
        },
        {
            field: "newLastName",
            title: "New Last Name",
            lookup: filterArrayToUniqueKeyValueObject(
                newFilters ? newDataFilters : fetchedData,
                "newLastName"
            ),
            render(row) {
                if (row.oldLastName !== row.newLastName) {
                    return (
                        <span className="highlight">
                            {row.newLastName}
                        </span>
                    );
                }
                else {
                    return (
                        <span>
                            {row.newLastName}
                        </span>
                    );
                }

            }
        },
        {
            field: "lastUpdatedFormat",
            title: "Modified Date",
            lookup: filterArrayToUniqueKeyValueObject(
                newFilters ? newDataFilters : fetchedData,
                "lastUpdatedFormat"
            ),

        },
        {
            field: "lastModifiedByName",
            title: "Last Modified By",
            cellStyle: { minWidth: 195 },
            lookup: filterArrayToUniqueKeyValueObject(
                newFilters ? newDataFilters : fetchedData,
                "lastModifiedByName"
            ),
        },
        {
            field: "oldEmail",
            title: "Old Primary Email",
            cellStyle: { minWidth: 195 },
            lookup: filterArrayToUniqueKeyValueObject(
                newFilters ? newDataFilters : fetchedData,
                "oldEmail"

            ),
            render(row) {
                if (row.oldEmail !== row.newEmail) {
                    return (
                        <span className="highlight">
                            {row.oldEmail}
                        </span>
                    );
                }
                else {
                    return (
                        <span>
                            {row.oldEmail}
                        </span>
                    );
                }

            }
        },
        {
            field: "newEmail",
            title: "New Primary Email",
            cellStyle: { minWidth: 195 },
            lookup: filterArrayToUniqueKeyValueObject(
                newFilters ? newDataFilters : fetchedData,
                "newEmail"

            ),
            render(row) {
                if (row.oldEmail !== row.newEmail) {
                    return (
                        <span className="highlight">
                            {row.newEmail}
                        </span>
                    );
                }
                else {
                    return (
                        <span>
                            {row.newEmail}
                        </span>
                    );
                }

            }
        },
        {
            field: "secondaryEmail",
            title: "Secondary Email",
            cellStyle: { minWidth: 195},
            lookup: filterArrayToUniqueKeyValueObject(
                newFilters ? newDataFilters : fetchedData,
                "secondaryEmail"
            ),
            render(row) {
                return (
                    <EllipsisText
                        text={row.secondaryEmail == null ? "" : row.secondaryEmail}
                        length={80}
                        tail='...'
                        tooltip=''
                    />
                )
            }
        },
        {
            field: "newSecondaryEmail",
            title: "Secondary Email Changes",
            cellStyle: { minWidth: 195, fontWeight: "bold" },
            lookup: filterArrayToUniqueKeyValueObject(
                newFilters ? newDataFilters : fetchedData,
                "newSecondaryEmail"
            ),
            render(row) {
                if (row.isUpdate) {
                    return (
                        <span style={{ color: "red", fontWeight: "bold" }}>
                            {row.newSecondaryEmail}
                        </span>
                    );
                }
                else {
                    return (
                        <span style={{ color: "green", fontWeight: "bold" }}>
                            {row.newSecondaryEmail}
                        </span>
                    );
                }
            }
        },
        {
            field: "oldRoleName",
            title: "Old Role Name",
            lookup: filterArrayToUniqueKeyValueObject(
                newFilters ? newDataFilters : fetchedData,
                "oldRoleName"
            ),
            render(row) {
                if (row.oldRoleName !== row.newRoleName) {
                    return (
                        <span className="highlight">
                            {row.oldRoleName}
                        </span>
                    );
                }
                else {
                    return (
                        <span>
                            {row.oldRoleName}
                        </span>
                    );
                }
            }
        },
        {
            field: "newRoleName",
            title: "New Role Name",
            lookup: filterArrayToUniqueKeyValueObject(
                newFilters ? newDataFilters : fetchedData,
                "newRoleName"
            ),
            render(row) {
                if (row.oldRoleName !== row.newRoleName) {
                    return (
                        <span className="highlight">
                            {row.newRoleName}
                        </span>
                    );
                }
                else {
                    return (
                        <span>
                            {row.newRoleName}
                        </span>
                    );
                }

            }
        },
        {
            field: "oldIsInternal",
            title: "Old User Type",
            lookup: { false: "External", true: "Internal" },
            render: (row) => {
                if (row.oldIsInternal !== row.newIsInternal) {
                    return (
                        <div><span className="highlight">{row.oldIsInternal ? "Internal" : "External"}</span></div>
                    );
                }
                else {
                    return (
                        <div>{row.oldIsInternal ? "Internal" : "External"}</div>
                    );
                }
            }
        },
        {
            field: "newIsInternal",
            title: "New User Type",
            lookup: { false: "External", true: "Internal" },
            render: (row) => {
                if (row.oldIsInternal !== row.newIsInternal) {
                    return (
                        <div><span className="highlight">{row.newIsInternal ? "Internal" : "External"}</span></div>
                    );
                }
                else {
                    return (
                        <div>{row.newIsInternal ? "Internal" : "External"}</div>
                    );
                }
            }
        },
        {
            field: "oldTitle",
            title: "Old Title",
            cellStyle: { minWidth: 195 },
            lookup: filterArrayToUniqueKeyValueObject(
                newFilters ? newDataFilters : fetchedData,
                "oldTitle"
            ),
            render(row) {
                if (row.oldTitle !== row.newTitle) {
                    return (
                        <span className="highlight">
                            {row.oldTitle}
                        </span>
                    );
                }
                else {
                    return (
                        <span>
                            {row.oldTitle}
                        </span>
                    );
                }
            }
        },
        {
            field: "newTitle",
            title: "New Title",
            cellStyle: { minWidth: 195 },
            lookup: filterArrayToUniqueKeyValueObject(
                newFilters ? newDataFilters : fetchedData,
                "newTitle"
            ),
            render(row) {
                if (row.oldTitle !== row.newTitle) {
                    return (
                        <span className="highlight">
                            {row.newTitle}
                        </span>
                    );
                }
                else {
                    return (
                        <span>
                            {row.newTitle}
                        </span>
                    );
                }
            }
        },
        {
            field: "oldOrganization",
            title: "Old Organization",
            cellStyle: { minWidth: 195 },
            lookup: filterArrayToUniqueKeyValueObject(
                newFilters ? newDataFilters : fetchedData,
                "oldOrganization"
            ),
            render(row) {
                if (row.oldOrganization !== row.newOrganization) {
                    return (
                        <span className="highlight">
                            {row.oldOrganization}
                        </span>
                    );
                }
                else {
                    return (
                        <span>
                            {row.oldOrganization}
                        </span>
                    );
                }
            }
        },
        {
            field: "newOrganization",
            title: "New Organization",
            cellStyle: { minWidth: 195 },
            lookup: filterArrayToUniqueKeyValueObject(
                newFilters ? newDataFilters : fetchedData,
                "newOrganization"
            ),
            render(row) {
                if (row.oldOrganization !== row.newOrganization) {
                    return (
                        <span className="highlight">
                            {row.newOrganization}
                        </span>
                    );
                }
                else {
                    return (
                        <span>
                            {row.newOrganization}
                        </span>
                    );
                }
            }
        },
        {
            field: "oldLocationCountry",
            title: "Old Location",
            cellStyle: { minWidth: 195 },
            lookup: filterArrayToUniqueKeyValueObject(
                newFilters ? newDataFilters : fetchedData,
                "oldLocationCountry"
            ),
            render(row) {
                if (row.oldLocationCountry !== row.newLocationCountry) {
                    return (
                        <span className="highlight">
                            {row.oldLocationCountry}
                        </span>
                    );
                }
                else {
                    return (
                        <span>
                            {row.oldLocationCountry}
                        </span>
                    );
                }
            }
        },
        {
            field: "newLocationCountry",
            title: "New Location",
            cellStyle: { minWidth: 195 },
            lookup: filterArrayToUniqueKeyValueObject(
                newFilters ? newDataFilters : fetchedData,
                "newLocationCountry"
            ),
            render(row) {
                if (row.oldLocationCountry !== row.newLocationCountry) {
                    return (
                        <span className="highlight">
                            {row.newLocationCountry}
                        </span>
                    );
                }
                else {
                    return (
                        <span>
                            {row.newLocationCountry}
                        </span>
                    );
                }
            }
        },
        {
            field: "oldOfficeNumber",
            title: "Old Phone Number",
            cellStyle: { minWidth: 195 },
            lookup: filterArrayToUniqueKeyValueObject(
                newFilters ? newDataFilters : fetchedData,
                "oldOfficeNumber"
            ),
            render(row) {
                if (row.oldOfficeNumber !== row.newOfficeNumber) {
                    return (
                        <span className="highlight">
                            {row.oldOfficeNumber}
                        </span>
                    );
                }
                else {
                    return (
                        <span>
                            {row.oldOfficeNumber}
                        </span>
                    );
                }
            }
        },
        {
            field: "newOfficeNumber",
            title: "New Phone Number",
            cellStyle: { minWidth: 195 },
            lookup: filterArrayToUniqueKeyValueObject(
                newFilters ? newDataFilters : fetchedData,
                "newOfficeNumber"
            ),
            render(row) {
                if (row.oldOfficeNumber !== row.newOfficeNumber) {
                    return (
                        <span className="highlight">
                            {row.newOfficeNumber}
                        </span>
                    );
                }
                else {
                    return (
                        <span>
                            {row.newOfficeNumber}
                        </span>
                    );
                }
            }
        },
        {
            field: "oldDepartment",
            title: "Old Department",
            cellStyle: { minWidth: 195 },
            lookup: filterArrayToUniqueKeyValueObject(
                newFilters ? newDataFilters : fetchedData,
                "oldDepartment"
            ),
            render(row) {
                if (row.oldDepartment !== row.newDepartment) {
                    return (
                        <span className="highlight">
                            {row.oldDepartment}
                        </span>
                    );
                }
                else {
                    return (
                        <span>
                            {row.oldDepartment}
                        </span>
                    );
                }
            }
        },
        {
            field: "newDepartment",
            title: "New Department",
            cellStyle: { minWidth: 195 },
            lookup: filterArrayToUniqueKeyValueObject(
                newFilters ? newDataFilters : fetchedData,
                "newDepartment"
            ),
            render(row) {
                if (row.oldDepartment !== row.newDepartment) {
                    return (
                        <span className="highlight">
                            {row.newDepartment}
                        </span>
                    );
                }
                else {
                    return (
                        <span>
                            {row.newDepartment}
                        </span>
                    );
                }
            }
        },
        {
            field: "oldParentGUID",
            title: "Old ParentGUID",
            cellStyle: { minWidth: 350 },
            lookup: filterArrayToUniqueKeyValueObject(
                newFilters ? newDataFilters : fetchedData,
                "oldParentGUID"
            ),
            render(row) {
                if (row.oldParentGUID !== row.newParentGUID) {
                    return (
                        <span className="highlight">
                            {row.oldParentGUID}
                        </span>
                    );
                }
                else {
                    return (
                        <span>
                            {row.oldParentGUID}
                        </span>
                    );
                }
            }
        },
        {
            field: "newParentGUID",
            title: "New ParentGUID",
            cellStyle: { minWidth: 350 },
            lookup: filterArrayToUniqueKeyValueObject(
                newFilters ? newDataFilters : fetchedData,
                "newParentGUID"
            ),
            render(row) {
                if (row.oldParentGUID !== row.newParentGUID) {
                    return (
                        <span className="highlight">
                            {row.newParentGUID}
                        </span>
                    );
                }
                else {
                    return (
                        <span>
                            {row.newParentGUID}
                        </span>
                    );
                }
            }
        },
        {
            field: "oldChildGUID",
            title: "Old ChildGUID",
            cellStyle: { minWidth: 350 },
            lookup: filterArrayToUniqueKeyValueObject(
                newFilters ? newDataFilters : fetchedData,
                "oldChildGUID"
            ),
            render(row) {
                if (row.oldChildGUID !== row.newChildGUID) {
                    return (
                        <span className="highlight">
                            {row.oldChildGUID}
                        </span>
                    );
                }
                else {
                    return (
                        <span>
                            {row.oldChildGUID}
                        </span>
                    );
                }
            }
        },
        {
            field: "newChildGUID",
            title: "New ChildGUID",
            cellStyle: { minWidth: 350 },
            lookup: filterArrayToUniqueKeyValueObject(
                newFilters ? newDataFilters : fetchedData,
                "newChildGUID"
            ),
            render(row) {
                if (row.newChildGUID !== row.oldChildGUID) {
                    return (
                        <span className="highlight">
                            {row.newChildGUID}
                        </span>
                    );
                }
                else {
                    return (
                        <span>
                            {row.newChildGUID}
                        </span>
                    );
                }
            }
        }
    ];


    return (
        <React.Fragment>
            <Row className="pt-4">
                <Col xs={12}>
                    {customAlert}
                    {loading ? (
                        <Spinner animation="border" />
                    ) : (
                            <CustomTable
                                columns={columns}
                                data={newFilters ? newDataData : fetchedData}
                                title="User Profile Change History"
                                showSearchBar={true}
                                fillHeight={true}
                                paddingTop={250}
                                selectedFilterItems={selectedFilterItems}
                                setSelectedFilterItems={setSelectedFilterItems}
                                offlineFilterHelpers={{
                                    originalData: fetchedData,
                                    setNewFilters,
                                    setNewDataFilters,
                                    setNewDataData,
                                }}
                            />
                        )}
                </Col>
            </Row>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => ({
    fetchedData: state.allUsersLog.allusersLogList,

});

export default connect(mapStateToProps, null)(AllUsersLogTable);