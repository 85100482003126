import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Modal, Button, Form, Row, Col, Spinner, Table } from "react-bootstrap";
import { returnTimeAndDateUTC } from "../../globalFunctions";
import { REQUEST_STATUS } from "../../enums";
import CustomDropdownButtonM from "../UI/CustomDropdownButton/CustomDropdownButtonM";
import CustomTextField from "../UI/CustomTextField/CustomTextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import { FaPlusCircle } from "react-icons/fa";
import { FaMinusCircle } from "react-icons/fa";
import TooltipForComponent from "../UI/Tooltip/TooltipForComponent";

import {
    removeStandardComment,
    addStandardComment,
    getAllStandardComments,
} from "../../actions/creators/userRequests";
import AutoLinkText from 'react-autolink-text2';


const ProvisionerModal = ({
  showModal,
  setShowModal,
  approvedRejectedBy,
  requestId,
  requestData,
  loadingModal,
  setLoadingModal,
  removeStandardComment,
  addStandardComment,
  StandardComments,
  approveRejectRequestProvisioner,
  fetchProvisionerRequestList,
}) => {
    const [comments, setComments] = useState("");
    const [newComment, setNewComment] = useState("");
    const [selectedComment, setSelectedComment] = useState("");
    const [selectedCommentId, setSelectedCommentId] = useState("");

    const changeSelection = (evt) => {
        evt = evt.target.value;
        setSelectedComment(StdComments[evt]);
        setSelectedCommentId(StdCommentsDesc[evt]);
        setComments(StdComments[evt]);
    };
    useEffect(() => {
        setSelectedComment("");
    }, [])

    const StdComments = StandardComments.length != 0 ? StandardComments.map((a) => a.comment) : [];
    var StdCommentsDesc = StandardComments.length != 0 ? StandardComments.map((a) => a.id) : [];

    const saveNewComment = evt => {
        setNewComment(evt.target.value);
    };
  
    const saveComments = (evt) => {
        setComments(evt.target.value);
    };
    const getColor = (Name) => {
        if (Name === "Super Administrator" || Name === "Administrator") return 'red';
        else
            return 'black';
    };

    const description = requestData.changesRequest;

const ApproverText =
    requestData.approverRejectedByUser !== null && !requestData.rejectedByApprover
        ? "Approved By"
        : "Rejected By";

    const ProvisionerText =
        requestData.provissionerUser !== null && !requestData.rejectedByApprover && requestData.statusId !== REQUEST_STATUS.COMPLETED
        ? "Provisioning Rejected By"
        : "Provisioned By";

    const haveComments = requestData.comments || requestData.approverComment || requestData.provisionerComment ? true : false;

  return (
    <Modal
      size="lg"
      show={showModal}
      onHide={() => {
        setShowModal(false);
          setLoadingModal(true);
          setSelectedComment("");
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>Provisioning Request #{requestId}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!loadingModal ? (
          <React.Fragment>
            <React.Fragment>
              <Row className="text-center pb-2">
                <Col xs={12} className="pt-2 pb-3">
                  <h4>Created By</h4>
                </Col>
                <Col xs={4}>
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    disabled
                    value={
                      requestData.createdByUser.name +
                      " " +
                      requestData.createdByUser.lastName
                    }
                  />
                </Col>
                <Col xs={4}>
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    disabled
                    value={requestData.createdByUser.email || ""}
                  />
                </Col>
                <Col xs={4}>
                  <Form.Label>Creation Date</Form.Label>
                  <Form.Control
                    disabled
                    value={returnTimeAndDateUTC(requestData.createdDate)}
                  />
                </Col>
              </Row>
              <hr />
            </React.Fragment>
            <Row className="text-center pt-2">
              <Col xs={12}>
                <h4>User Information</h4>
              </Col>
              <Col xs={6}>
                <Form.Label>User Name</Form.Label>
                <Form.Control
                  disabled
                  value={
                    requestData.requestUser.name +
                    " " +
                    requestData.requestUser.lastName
                  }
                />
              </Col>
              <Col xs={6}>
                <Form.Label>User Type</Form.Label>
                <Form.Control
                  disabled
                  value={requestData.isInternal ? "Internal" : "External"}
                />
              </Col>
            </Row>
            <Row className="text-center">
              <Col xs={6}>
                <Form.Label>User Primary Email</Form.Label>
                <Form.Control disabled value={requestData.requestUser.email} />
              </Col>
              <Col xs={6}>
                <Form.Label>User Platform Email</Form.Label>
                <Form.Control disabled value={requestData.email} />
              </Col>
            </Row>
            <Row className="text-center">
              <Col xs={6}>
                <Form.Label>Title</Form.Label>
                <Form.Control
                  disabled
                  value={requestData.requestUser.title || ""}
                />
              </Col>
              <Col xs={6}>
                <Form.Label>User Agency or Organization</Form.Label>
                <Form.Control
                  disabled
                  value={requestData.requestUser.organization || ""}
                />
              </Col>
            </Row>
            <Row className="text-center">
              <Col xs={6}>
                <Form.Label>Department</Form.Label>
                <Form.Control
                  disabled
                  value={requestData.requestUser.department || ""}
                />
                          </Col>
                          <Col xs={6}>
                              <Form.Label>Phone Number</Form.Label>
                              <Form.Control
                                  disabled
                                  value={requestData.requestUser.officeNumber || ""}
                              />
                          </Col>
                      </Row>
                          <Row className="text-center">
              <Col xs={12}>
                <Form.Label>Location</Form.Label>
                <Form.Control
                  disabled
                  value={requestData.requestUser.locationCountry || ""}
                />
              </Col>
            </Row>
            <hr />
            <Row className="text-center">
              <Col xs={12} className="pt-2 pb-3">
                <h4>Platform Access Information</h4>
              </Col>
              <Col xs={4}>
                <Form.Label>
                  <h5>Request Type</h5>
                </Form.Label>
                <Form.Control
                  disabled
                  style={{ fontWeight: "bold" }}
                  value={requestData.requestTypeName}
                />
              </Col>
              <Col xs={4}>
                <Form.Label>
                  <h5>Region</h5>
                </Form.Label>
                <Form.Control disabled value={requestData.regionName} />
              </Col>
              <Col xs={4}>
                <Form.Label>
                  <h5>Market</h5>
                </Form.Label>
                <Form.Control disabled value={requestData.marketName} />
              </Col>
            </Row>
            <Row className="text-center pt-1">
              <Col xs={4}>
                <Form.Label>
                  <h5>Agency</h5>
                </Form.Label>
                <Form.Control disabled value={requestData.agencyName} />
              </Col>
              <Col xs={4}>
                <Form.Label>
                  <h5>Practice</h5>
                </Form.Label>
                <Form.Control disabled value={requestData.practiceName} />
              </Col>
              <Col xs={4}>
                <Form.Label>
                  <h5>Platform</h5>
                </Form.Label>
                <Form.Control disabled value={requestData.platformName} />
              </Col>
            </Row>
            <Row className="text-center pt-1">
              <Col xs={4}>
                <Form.Label>
                  <h5>Client</h5>
                </Form.Label>
                <Form.Control disabled value={requestData.clientName} />
              </Col>
              <Col xs={4}>
                <Form.Label>
                  <h5>Client Code</h5>
                </Form.Label>
                <Form.Control disabled value={requestData.clientCodeName} />
              </Col>
              <Col xs={4}>
                <Form.Label>
                  <h5>Account Ownership</h5>
                </Form.Label>
                <Form.Control disabled value={requestData.ownerName} />
              </Col>
            </Row>
            <hr />
            <Row className="pt-1">
              <Col xs={12} className="text-center">
                <h4>Selected Accounts</h4>
              </Col>
              <Col xs={{ span: 10, offset: 1 }}>
                <Table size="sm" className="table-background-color">
                  <thead>
                   <tr>
                      <th>Asset Type</th>
                      <th>Asset Name</th>
                      <th>Asset ID</th>
                      <th>Platform User Type</th>
                      <th>Length of Access</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>        
                      <td>{requestData.assetName}</td>
                      <td style={{ wordBreak:'break-word' }}>{requestData.clientAccountName}</td>
                      <td>{requestData.assetReference}</td>
                      <td style={{ color: getColor(requestData.platformUserType.basicName) }}>{requestData.platformUserTypeName}</td>
                      <td>
                        {requestData.beginningDate && !requestData.isUnlimited ? (
                          <React.Fragment>
                            {new Date(
                              requestData.beginningDate
                            ).toLocaleDateString()}{" "}
                            -{" "}
                            {new Date(
                              requestData.expirationDate
                            ).toLocaleDateString()}
                          </React.Fragment>
                        ) : (
                          "Unlimited"
                        )}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </Row>
           {haveComments ? (
           <React.Fragment>
                <hr />
                <Row>
                    <Col xs={12} className="text-center">
                        <h4>Comments</h4>
                    </Col>
                    <Col xs={{ span: 10, offset: 1 }}>
                        <Table size="sm" className="table-background-color">
                            <thead>
                                <tr>
                                    <th>From</th>
                                    <th>Comments</th>
                                </tr>
                            </thead>
                            <tbody>
                                {requestData.comments ? (
                                    <tr>
                                                      <td>Requestor</td>
                                                      <td>{requestData.comments}</td>
                                    </tr>
                                ) : null}
                                {requestData.approverComment ? (
                                    <tr>
                                        <td>Approver</td>
                                        <td>{requestData.approverComment}</td>
                                    </tr>
                                ) : null}
                                {requestData.provisionerComment ? (
                                    <tr>
                                        <td>Provisioner</td>
                                        <td>{requestData.provisionerComment}</td>
                                    </tr>
                                ) : null}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </React.Fragment>
        ) : null}
            {requestData.changesRequest !== null &&
            requestData.changesRequest !== "" ? (
              <React.Fragment>
               <hr />
                <Row className="text-center">
                  <Col xs={12} sm={{ span: 8, offset: 2 }}>
                    <Form.Label>
                      <h4>Changes</h4>
                    </Form.Label>
                  </Col>
                  <Col xs={{ span: 10, offset: 1 }}>
                    <Table size="sm" className="table-background-color">
                      <tbody>
                        <tr>
                          <td>
                            <b>Description</b>
                          </td>
                          <td>{requestData.changesRequest}</td>
                        </tr>
                      </tbody>
                    </Table>
                  </Col>
                </Row>
                <hr />
              </React.Fragment>
            ) : null}
            {requestData.approvedRejectedBy !== null? (
              <React.Fragment>
                <hr />
                <Row className="text-center">
                  <Col xs={12} sm={{ span: 8, offset: 2 }}>
                    <Form.Label>
                      <h4>{ApproverText}</h4>
                    </Form.Label>
                  </Col>
                </Row>
                <Row>
                  <Col xs={4}>
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      disabled
                      value={
                        (requestData.approverRejectedByUser
                          ? requestData.approverRejectedByUser.name
                          : "") +
                        " " +
                        (requestData.approverRejectedByUser
                          ? requestData.approverRejectedByUser.lastName
                          : "")
                      }
                    />
                  </Col>
                  <Col xs={5}>
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      disabled
                      value={
                        requestData.approverRejectedByUser
                          ? requestData.approverRejectedByUser.email
                          : ""
                      }
                    />
                  </Col>
                  <Col xs={3}>
                    <Form.Label>Date</Form.Label>
                    <Form.Control
                      disabled
                      value={
                        requestData.approvedDate
                          ? new Date(
                              requestData.approvedDate
                            ).toLocaleDateString()
                          : ""
                      }
                    />
                  </Col>
                </Row>
                <hr />
              </React.Fragment>
            ) : null}
            {requestData.provissionerId !== null? (
              <React.Fragment>
                <Row className="text-center">
                  <Col xs={12} sm={{ span: 8, offset: 2 }}>
                    <Form.Label>
                      <h4>{ProvisionerText}</h4>
                    </Form.Label>
                  </Col>
                </Row>
                <Row>
                  <Col xs={4}>
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      disabled
                      value={
                        (requestData.provissionerUser
                          ? requestData.provissionerUser.name
                          : "") +
                        " " +
                        (requestData.provissionerUser
                          ? requestData.provissionerUser.lastName
                          : "")
                      }
                    />
                  </Col>
                  <Col xs={5}>
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      disabled
                      value={
                        requestData.provissionerUser
                          ? requestData.provissionerUser.email
                          : ""
                      }
                    />
                  </Col>
                  <Col xs={3}>
                    <Form.Label>Date</Form.Label>
                    <Form.Control
                      disabled
                      value={
                        requestData.provissionedDate
                          ? new Date(
                              requestData.provissionedDate
                            ).toLocaleDateString()
                          : ""
                      }
                    />
                  </Col>
                </Row>
                <hr />
              </React.Fragment>
                      ) : null}
                      <React.Fragment>
                          {requestData.status.statusId === 2 ? (
                      <Row className="text-center">
                          <Col xs={12} sm={{ span: 8, offset: 2 }}>
                              <Form.Label>
                                  <h4>Standard Comment</h4>
                              </Form.Label>
                          </Col>
                      </Row>
                          ) : null}
                          {requestData.status.statusId === 2 ? (
                      <Row>
                          <Col xs={12} sm={{ span: 5, offset: 0 }} className="text-left">
                                  <CustomDropdownButtonM
                                  label="Select Comment"
                                  id="select-comment-dropdown"
                                  onSelect={changeSelection}
                                  items={StdComments}
                                  tooltipText="Select Comment"
                                  />
                          </Col>
                          <Col xs={12} sm={2} className="text-center my-auto">
                              <h5>OR</h5>
                          </Col>
                          <Col xs={12} sm={{ span: 5, offset: 0 }}>
                              <CustomTextField
                                  label="Add new Comment"
                                  defaultValue={newComment}
                                  onChange={saveNewComment}
                                  InputProps={{
                                      endAdornment: (
                                          <InputAdornment position="end">
                                              <TooltipForComponent text="Add Comment" placement="top">
                                                  <FaPlusCircle
                                                      size={25}
                                                      style={{ color: "rgb(135 203 156)" }}
                                                      className="pointer-cursor"
                                                      onClick={() => {
                                                          if (!newComment.replace(/\s/g, "").length) {
                                                              alert("You have to input a comment to add.");
                                                          }
                                                          else {
                                                              addStandardComment('Provisioning', newComment, setShowModal);
                                                              setNewComment("");
                                                              setSelectedComment("");
                                                          }

                                                      }}
                                                  />
                                              </TooltipForComponent>
                                              <TooltipForComponent text="Remove Comment" placement="top">
                                                  <FaMinusCircle
                                                      size={25}
                                                      style={{ color: "rgb(135 203 156)" }}
                                                      className="pointer-cursor"
                                                      onClick={() => {
                                                          if (selectedComment == "" || selectedComment == undefined) {
                                                              alert("You have to select a comment.");
                                                          }
                                                          else {
                                                              removeStandardComment(selectedCommentId, setShowModal);
                                                              setSelectedComment("");
                                                              
                                                          }

                                                      }}
                                                  />
                                              </TooltipForComponent>
                                          </InputAdornment>
                                      )
                                  }}
                                  id="add-comment"
                              />
                          </Col>
                      </Row>
                          ) : null}
                          {requestData.status.statusId === 2 ? (
                          <hr />
                          ) : null}
                      </React.Fragment>
            {requestData.status.statusId === 2 ? (
              <Row>
                <Col xs={{ span: 10, offset: 1 }}>
                  <Form.Group>
                    <Form.Label>
                    <h5>Leave a comment <b style={{ color: "red" }}>*</b></h5>
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      rows="3"
                      defaultValue={selectedComment}
                      key={selectedComment}
                      onChange={saveComments}
                      maxLength={500}
                    />
                  </Form.Group>
                </Col>
              </Row>
            ) : null}
          </React.Fragment>
        ) : (
          <Row className="text-center">
            <Col xs={12}>
              <Spinner animation="border" size="lg" />
            </Col>
          </Row>
        )}
      </Modal.Body>
      {!loadingModal ? (
        <div>
          {requestData.status.statusId === 2 ? (
            <Modal.Footer>
                          <Row className="text-center">
                              <Button
                                  className="ml-2"
                                  variant="success"
                                  onClick={() => {
                                      if (!comments.replace(/\s/g, "").length){
                                          alert("You have to input a comment.");
                                      } else {
                                          setLoadingModal(true);
                                          setSelectedComment("");
                                          approveRejectRequestProvisioner(
                                              requestId,
                                              comments,
                                              approvedRejectedBy,
                                              true,
                                              requestData.isPlatformRequest,
                                              fetchProvisionerRequestList,
                                              setShowModal,
                                              description
                                          );
                                      }
                                  }}
                              >
                                  Approve
                              </Button>
                <Button
                  className="ml-2"
                  variant="danger"
                  onClick={() => {
                      if (!comments.replace(/\s/g, "").length){
                      alert("You have to input a comment.");
                    } else {
                          setLoadingModal(true);
                          setSelectedComment("");
                      approveRejectRequestProvisioner(
                        requestId,
                        comments,
                        approvedRejectedBy,
                        false,
                        requestData.isPlatformRequest,
                        fetchProvisionerRequestList,
                          setShowModal,
                        description
                      );
                    }
                  }}
                >
                  Reject
                </Button>
               
              </Row>
            </Modal.Footer>
          ) : null}
        </div>
      ) : null}
    </Modal>
  );
};

//export default ProvisionerModal;
const mapStateToProps = (state) => ({
});

export default connect(mapStateToProps, {
    addStandardComment,
    removeStandardComment
})(ProvisionerModal);
