import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Col, Row, Button, Spinner } from "react-bootstrap";
import { MdInfoOutline } from "react-icons/md";
import LogsButton from "../UI/LogsButton/UsersLogButton";
import UserDetailListButton from "../UI/LogsButton/UserDetailListButton";
import TooltipForComponent from "../UI/Tooltip/TooltipForComponent";
import { REQUEST_STATUS } from "../../enums";
import EditButton from "../UI/EditButton/EditButton";
import SyncButton from "../UI/SyncButton/SyncButton";
import ModalSyncUser from "./ModalSyncUser";
import ModalNewModifyUser from "./ModalNewModifyUser";
import EllipsisText from "react-ellipsis-text";
import { returnTimeAndDateUTC, returnDateUTC } from "../../globalFunctions";
import CustomTable, {
    filterArrayToUniqueKeyValueObject,
} from "../UI/CustomTable/CustomTable";
import { approverToStandardUsers, profileMatcherRunningStatus, userStatusReportRunningStatus } from "../../actions/creators/userList";
import { changeStatusUser } from "../../actions/creators/approvers";

import CustomCall from "../../actions/CustomCall/CustomCall";
import {
    Modal,
    Form,
    ButtonToolbar,
    ButtonGroup,
    Card,
    ListGroup,
} from "react-bootstrap";



const AllUsersTable = ({
    //Props Redux
    fetchedDataForPopup
}) => {
    const [loading, setLoading] = useState(false);
    //  const [fetchedData, fetchAllUsersList] = useState(false);
    const [customAlert, setCustomAlert] = useState(null);
    //const [, setShowAlert] = useState(false);

    //Filter filters helpers
    const [newFilters, setNewFilters] = useState(false); //Know if any filter is applied.
    const [newDataFilters, setNewDataFilters] = useState([]); //Data after filter.
    const [newDataData, setNewDataData] = useState([]); //Filters after filter.
    const [selectedFilterItems, setSelectedFilterItems] = useState({});
    const [isEditingForUser, setIsEditingForUser] = useState(false);
    const [showUserModal, setShowUserModal] = useState(false);
    const [isSyncForUser, setIsSyncForUser] = useState(false);
    const [showUserSyncModal, setShowSyncUserModal] = useState(false);
    const [filters, setFilters] = useState({});
    const [filterExcluded, setFilterExcluded] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [, setShowAlert] = useState(false);
    const [tableRef] = useState(React.createRef());
    
    const [status, setstatus] = useState(false);
    const [starton, setstarton] = useState(null);
    const [endon, setendon] = useState(null);

    const [statusReportStarton, setstatusReportStarton] = useState(null);
    const [statusReportEndon, setstatusReportEndon] = useState(null);

    const [reportStatus, setReportStatus] = useState(false);
    const [validatereportStatus, setValidateReportStatus] = useState(false);
    const [showApproverProvisionerModal, setShowApproverProvisionerModal] = useState(false);
    const [fetchedApproverProvisionerData, setFetchedApproverProvisionerData] = useState([]);

    //Popup filter
    const [newPopupFilters, setNewPopupFilters] = useState(false); //Know if any filter is applied.
    const [newPopupDataFilters, setNewPopupDataFilters] = useState([]); //Data after filter.
    const [newPopupDataData, setNewPopupDataData] = useState([]); //Filters after filter.
    const [selectedPopupFilterItems, setSelectedPopupFilterItems] = useState({});
    const [selectedPopupRows, setSelectedPopupRows] = useState([]);

    const current = new Date();
    const date = `${current.getMonth() + 1}/${current.getDate()}/${current.getFullYear()}`;

    const getUpdatedFilter = (newFilters, fieldName, lookupName) => {
        const currentFilters = filters;
        const itemsArray = Object.values(selectedFilterItems);
        const selectedFilters = itemsArray.find((x) => x.field === fieldName);
        if (
            filterExcluded === fieldName &&
            selectedFilters &&
            selectedFilters.value.length > 0
        ) {
            if (selectedFilters.value.some((x) => x.trim().length > 0)) {
                newFilters[lookupName] = currentFilters[lookupName];
            }
        }
    };
    const showApproverProvisionModal = (e) => {
        userStatusReportRunningStatus('UserStatusReport', setstatusReportStarton, setstatusReportEndon);
        loadApproverProvisionarDataGrid();
        setShowApproverProvisionerModal(true);
    };
    const onPopupTableSelectionChange = (rows) => {
        setSelectedPopupRows(rows);
    };
    const acknowledgeProfiles = () => {
        var confirmBox = window.confirm("Are you sure you want to acknowledge selected profiles?");
        if (confirmBox == true) {
            let url = "api/User/AcknowledgeUser";
            let data = {
                Ids: selectedPopupRows.map((x) => x.id),
                IsDeleted: !selectedPopupRows[0].isDeleted,
            };
            CustomCall(
                "put",
                url,
                data,
                (response) => {
                    if (response.data.succeeded) {
                        setNewPopupFilters(false);
                        setSelectedPopupFilterItems({});
                        setSelectedPopupRows([]);
                        loadApproverProvisionarDataGrid();
                        alert("Acknowledged successfully");
                    } else {
                        alert(response.data.message);
                    }
                },
                setCustomAlert,
                setShowAlert
            );
        }
    };
    
    
    let columnsApprovers = [
        {
            title: "Actions",
            width: "10%",
            render: (row) => {
                return (
                    <React.Fragment>
                        <TooltipForComponent
                            text={
                                "Acknowledge this user"
                            }
                            placement="top"
                        >
                            <Button
                                variant="link"
                                onClick={() => {
                                    changeStatusUser(row.id, !row.isDeleted, () => {
                                        setNewPopupFilters(false);
                                        setSelectedPopupFilterItems({});
                                        loadApproverProvisionarDataGrid();
                                    });
                                }}
                            >
                                {"Acknowledge"}
                            </Button>
                        </TooltipForComponent>
                    </React.Fragment>
                );
            },
        },
        {
            title: "Is Merged",
            field: "isMerged",
            lookup: { false: "No", true: "Yes" },
            render: (row) => {
                return <div>{row.isMerged ? (
                    <UserDetailListButton userId={row.userID}/>
                ) : null}</div>;
            },
        },
        {
            field: "userID",
            title: "UAP Id", width: "10%"
        },
        
        {
            field: "fullName",
            title: "User Name", width: "15%",
            customSort: (a, b) => (a.fullName != null ? a.fullName.toUpperCase() : "").localeCompare(b.fullName != null ? b.fullName.toUpperCase() : ""),
            lookup: filterArrayToUniqueKeyValueObject(
                newPopupFilters ? newPopupDataFilters : fetchedApproverProvisionerData,
                "fullName"
            ),
        },
        {
            field: "role",
            title: "UAP Role Name",
            width: "15%",
            customSort: (a, b) => a.role.toUpperCase().localeCompare(b.role.toUpperCase()),
            lookup: filterArrayToUniqueKeyValueObject(
                newPopupFilters ? newPopupDataFilters : fetchedApproverProvisionerData,
                "role"
            ),
            render(row) {
                if (row.role == 'Approver' || row.role == 'Provisioner' ) {
                    return (
                        <React.Fragment>
                                <TooltipForComponent
                                    text={
                                        "Click here to redirect to approve/provision assignments"
                                    }
                                    placement="top"
                                >
                                <Button style={{ textAlign: 'left' }}
                                        variant="link"
                                    onClick={() => {
                                        const win = window.open('Approvers?userId=' + row.userID, '_blank');
                                        win.focus();
                                        }}
                                    >
                                    {row.role}
                                    </Button>
                                </TooltipForComponent>
                        </React.Fragment>

                    );
                } 
                else if (row.role == 'Master Approver')
                {
                    return (
                        <React.Fragment>
                            <TooltipForComponent
                                text={
                                    "Click here to redirect to master approver assignments"
                                }
                                placement="top"
                            >
                                <Button style={{ textAlign: 'left' }}
                                    variant="link"
                                    onClick={() => {
                                        const win = window.open('MasterApprovers?userId=' + row.userID, '_blank');
                                        win.focus();
                                    }}
                                >
                                    {row.role}
                                </Button>
                            </TooltipForComponent>
                        </React.Fragment>

                    );
                }
                else {
                    return (
                        <span>
                            {row.role}
                        </span>
                    );
                }
            }
        },
        {
            field: "operationDateFormat",
            title: "Modified Date", width: "10%",
            lookup: filterArrayToUniqueKeyValueObject(
                newPopupFilters ? newPopupDataFilters : fetchedApproverProvisionerData,
                "operationDateFormat"
            ),
        },
        {
            field: "activeRecordsCount",
            title: "Active Records Count", width: "5%",
              render: (row) => {
                return (
                    <React.Fragment>
                        {row.activeRecordsCount > 0 ? (
                            <TooltipForComponent
                                text={
                                    "Click here to redirect to modify an existing access for this user"
                                }
                                placement="top"
                            >
                                <Button
                                    variant="link"
                                    onClick={() => {
                                        window.open('ChangeUserRecords?userId=' + row.userID, '_blank');
                                    }}
                                >
                                    {row.activeRecordsCount}
                                </Button>
                            </TooltipForComponent>
                        ) :
                            <Button
                                variant="link"
                            >
                                {row.activeRecordsCount}
                            </Button>
                    }
                        
                    </React.Fragment>
                );
            },
        },
        {
            field: "agency",
            title: "Agency",
            width: "5%"
        },

        {
            field: "changeDescription",
            title: "Description",
            width: "30%",
            sorting: false,
            lookup: filterArrayToUniqueKeyValueObject(
                newPopupFilters ? newPopupDataFilters : fetchedApproverProvisionerData,
                "changeDescription"
            ),
        }

    ];

    const updateUserStatusReport = () => {
        setReportStatus(true);
        CustomCall(
            "get",
            "api/User/UpdateUserStatusReport",
            {},
            function (response) {
                alert('New User Status Report was created.');
                loadApproverProvisionarDataGrid();
                userStatusReportRunningStatus('UserStatusReport', setstatusReportStarton, setstatusReportEndon);
                setReportStatus(false);
            },
            setCustomAlert,
            setShowAlert
        );
    };
    const ValidateUserStatusReport = () => {
        setReportStatus(true);
        CustomCall(
            "get",
            "api/User/ValidateUserStatusReport",
            {},
            function (response) {
                alert('User Status Report was Validated successfully.');
                loadApproverProvisionarDataGrid();
                setReportStatus(false);
            },
            setCustomAlert,
            setShowAlert
        );
    };

    const loadApproverProvisionarDataGrid = () => {
        CustomCall(
            "get",
            "api/Approvers/CheckApprovalGrid",
            {},
            function (response) {
                setFetchedApproverProvisionerData(response.data.data);
            },
            setCustomAlert,
            setShowAlert
        );
    };
    const getTableFilters = (bodyFilter) => {
        setIsLoading(true);
        CustomCall(
            "put",
            `/api/User/ProfileFilters/`,
            bodyFilter,
            (response) => {
                const newFilters = response.data.data;
                // --------------------------
                getUpdatedFilter(newFilters, "createdDate", "createdDates");

                getUpdatedFilter(
                    newFilters,
                    "userId",
                    "userIds"
                );
                getUpdatedFilter(
                    newFilters,
                    "name",
                    "names"
                );

                getUpdatedFilter(
                    newFilters,
                    "lastName",
                    "lastNames"
                );
                getUpdatedFilter(newFilters, "isInternal", "areInternal");
                getUpdatedFilter(newFilters, "roleName", "roleNames");
                getUpdatedFilter(newFilters, "email", "emails");
                getUpdatedFilter(newFilters, "secondaryEmail", "secondaryEmails");
                getUpdatedFilter(newFilters, "title", "title");
                getUpdatedFilter(newFilters, "organization", "organizations");
                getUpdatedFilter(newFilters, "locationCountry", "locationCountrys");
                getUpdatedFilter(newFilters, "officeNumber", "officeNumbers");
                getUpdatedFilter(newFilters, "department", "departments");
                getUpdatedFilter(newFilters, "parentGUID", "ParentGUIDs");
                getUpdatedFilter(newFilters, "childGUID", "childGUIDs");
                getUpdatedFilter(newFilters, "isMergeButtonEnabled", { true: "Yes" });
                getUpdatedFilter(newFilters, "isSyncButtonEnabled", { true: "Yes" });
                setFilters(newFilters);

                setIsLoading(false);
            },
            setCustomAlert,
            setShowAlert
        );
    };
    const getBodyData = (query) => {
       
        const data = {};
        data.orderBy = query.orderBy === undefined ? "" : query.orderBy.field;
        data.orderAsc = (query.orderDirection || "desc") === "asc" ? true : false;
        data.page = query.page;
        data.pageSize = query.pageSize;
        data.justCompleted =true;
        data.initialLoad = true;
        data.filters = {};
        data.searchCriteria = query.search || "";
        Object.keys(selectedFilterItems).forEach(function (key) {
            data.filters[selectedFilterItems[key].field] =
                selectedFilterItems[key].value;
        });
        getTableFilters(data);

        return data;
    };

    const urlBuilder = () => "/api/User/GetAllUsers";

    const columns = [
        {
            title: "Log",
            export: false,
            sorting: false,
            headerStyle: ({ maxWidth: 60 }),
            cellStyle: ({ maxWidth: 60 }),
            render: (row) => {
                return (
                    <div style={{ marginTop: '-10px' }}>
                        <LogsButton userId={row.userId} />
                    </div>
                );

            },
        },
        {
            title: "Edit",
            export: false,
            sorting: false,
            headerStyle: ({ maxWidth: 60 }),
            cellStyle: ({ maxWidth: 60 }),
            render: (row) => {
                return (
                    <div style={{ marginTop: '-10px' }}>
                            {!row.isChild ? (
                            <EditButton userRow={row}
                                setIsEditingForUser={setIsEditingForUser}
                                setShowUserModal={setShowUserModal}
                            />
                        ) : null}
                    </div>
                );

            },
        },
        {
            title: "Sync",
            field: "isSyncButtonEnabled",
            export: true,
            sorting: false,
            headerStyle: ({ maxWidth: 60 }),
            cellStyle: ({ maxWidth: 60 }),
            lookup: { true: "Yes" },
            render: (row) => {
                return (
                    <div style={{ marginTop: '-10px' }}>
                        {row.azureADUserInfo != null ? (
                            <SyncButton userRow={row}
                                setIsSyncForUser={setIsSyncForUser}
                                setShowSyncUserModal={setShowSyncUserModal}
                            />
                        ) : null}
                    </div>
                );

            },
        },
        {
            title: "Is Merged",
            field: "isMergeButtonEnabled",
            export: true,
            sorting: false,
            headerStyle: ({ maxWidth: 70 }),
            cellStyle: ({ maxWidth: 70 }),
            lookup: { true: "Yes" },
            render: (row) => {
                return (
                    <div style={{ marginTop: '-10px' }}>
                        {row.mergerdUserids.includes(row.userId) == true ? (
                            <UserDetailListButton userId={row.userId} />
                        ) : null
                        }
                    </div>
                );
            },
        },
        {
            field: "userId",
            title: "UAP Id",
            cellStyle: { minWidth: 100 },
          //  lookup: filters.userIds || {},
            render(row) {
                if (row.isChild) {
                    return (
                        <span title={`Corresponding users merged with ${row.mergedUserId}`}>
                            {row.userId} ({row.mergedUserId})
                        </span>

                    );
                }
                else {
                    return (
                        <span>
                            {row.userId}
                        </span>
                    );
                }

            }
        },
        {
            field: "name",
            title: "First Name",
            customSort: (a, b) => (a.name != null ? a.name.toUpperCase() : "").localeCompare(b.name != null ? b.name.toUpperCase() : ""),
            lookup: filters.names || {},
            render(row) {
                if (row.isChild) {
                    return (
                        <span title={`Corresponding users merged with ${row.mergedUserId}`}>
                            {row.name}
                        </span>

                    );
                }
                else {
                    return (
                        <span>
                            {row.name}
                        </span>
                    );
                }

            }

        },
        {
            field: "lastName",
            title: "Last Name",
            customSort: (a, b) => (a.lastName != null ? a.lastName.toUpperCase() : "").localeCompare(b.lastName != null ? b.lastName.toUpperCase() : ""),
            lookup: filters.lastNames || {},
            render(row) {
                if (row.isChild) {
                    return (
                        <span title={`Corresponding users merged with ${row.mergedUserId}`}>
                            {row.lastName}
                        </span>

                    );
                }
                else {
                    return (
                        <span>
                            {row.lastName}
                        </span>
                    );
                }

            }

        },
        {
            field: "createdDate",
            title: "Created Date",
            lookup: filters.createdDates || {},
            render: (row) => {
                return new Date(row.createdDateFormat).toLocaleDateString();
            },
            render(row) {
                if (row.isChild) {
                    return (
                       <span title={`Corresponding users merged with ${row.mergedUserId}`}>
                            {new Date(row.createdDateFormat).toLocaleDateString()}
                        </span>

                    );
                }
                else {
                    return (
                        <span>
                            {new Date(row.createdDate).toLocaleDateString()}
                        </span>
                    );
                }

            }
        },
        {
            field: "isInternal",
            title: "User Type",
            lookup: { false: "External", true: "Internal" },
                render(row) {
                    if (row.isChild) {
                        return (
                            <div title="Corresponding users merged with this record">{row.isInternal ? "Internal" : "External"}</div>
                        );
                    }
                    else {
                        return (
                            <div>{row.isInternal ? "Internal" : "External"}</div>
                        );
                    }

                }
            },
        
        {
            field: "roleName",
            title: "UAP Role Name",
            customSort: (a, b) => a.roleName.toUpperCase().localeCompare(b.roleName.toUpperCase()),
            lookup: filters.roleNames || {},
            render(row) {
                if (row.isChild) {
                    return (
                       <span title={`Corresponding users merged with ${row.mergedUserId}`}>
                            {row.roleName}
                        </span>

                    );
                }
                else {
                    return (
                        <span>
                            {row.roleName}
                        </span>
                    );
                }
            }
        },
        {
            field: "email",
            title: "Primary Email",
            customSort: (a, b) => a.email.toUpperCase().localeCompare(b.email.toUpperCase()),
            cellStyle: { minWidth: 195 },
            lookup: filters.emails || {},
            render(row) {
                if (row.isChild) {
                    return (
                       <span title={`Corresponding users merged with ${row.mergedUserId}`}>
                            {row.email}
                        </span>
                    );
                }
                else {
                    return (
                        <span>
                            {row.email}
                        </span>
                    );
                }

            }
        },
        {
            field: "secondaryEmail",
            title: "Secondary Email",
            cellStyle: { minWidth: 195 },
            sorting: false,
            customSort: (a, b) => (a.secondaryEmail != null ? a.secondaryEmail.toUpperCase() : "").localeCompare(b.secondaryEmail != null ? b.secondaryEmail.toUpperCase() : ""),
            lookup: filters.secondaryEmail || {},
            render(row) {
                if (row.isChild) {
                    return (

                       <span title={`Corresponding users merged with ${row.mergedUserId}`}>
                            <EllipsisText
                                text={row.secondaryEmail == null ? "" : row.secondaryEmail}
                                length={80}
                                tail='...'
                                tooltip=''
                            />
                        </span>

                    )
                }
                else {
                    return (
                        <span>
                            <EllipsisText
                                text={row.secondaryEmail == null ? "" : row.secondaryEmail}
                                length={80}
                                tail='...'
                                tooltip=''
                            />
                        </span>
                    );
                }
            }
        },
        {
            field: "title",
            title: "Title",
            cellStyle: { minWidth: 195 },
            customSort: (a, b) => (a.title != null ? a.title.toUpperCase() : "").localeCompare(b.title != null ? b.title.toUpperCase() : ""),
            lookup: filters.titles || {},
            render(row) {
                if (row.isChild) {
                    return (
                       <span title={`Corresponding users merged with ${row.mergedUserId}`}>
                            {row.title}
                        </span>

                    );
                }
                else {
                    return (
                        <span>
                            {row.title}
                        </span>
                    );
                }

            }

        }, {
            field: "organization",
            title: "Organization",
            cellStyle: { minWidth: 195 },
            customSort: (a, b) => (a.organization != null ? a.organization.toUpperCase() : "").localeCompare(b.organization != null ? b.organization.toUpperCase() : ""),
            lookup: filters.organizations || {},
            render(row) {
                if (row.isChild) {
                    return (
                       <span title={`Corresponding users merged with ${row.mergedUserId}`}>
                            {row.organization}
                        </span>

                    );
                }
                else {
                    return (
                        <span>
                            {row.organization}
                        </span>
                    );
                }

            }

        },
        {
            field: "locationCountry",
            title: "Location",
            cellStyle: { minWidth: 195 },
            customSort: (a, b) => (a.locationCountry != null ? a.locationCountry.toUpperCase() : "").localeCompare(b.locationCountry != null ? b.locationCountry.toUpperCase() : ""),
            lookup: filters.locationCountrys || {},
            render(row) {
                if (row.isChild) {
                    return (
                       <span title={`Corresponding users merged with ${row.mergedUserId}`}>
                            {row.locationCountry}
                        </span>

                    );
                }
                else {
                    return (
                        <span>
                            {row.locationCountry}
                        </span>
                    );
                }

            }


        },
        {
            field: "officeNumber",
            title: "Phone Number",
            customSort: (a, b) => (a.officeNumber != null ? a.officeNumber.toUpperCase() : "").localeCompare(b.officeNumber != null ? b.officeNumber.toUpperCase() : ""),
            cellStyle: { minWidth: 195 },
            lookup: filters.officeNumbers || {},
            render(row) {
                if (row.isChild) {
                    return (
                        <span title={`Corresponding users merged with ${row.mergedUserId}`}>
                            {row.officeNumber}
                        </span>

                    );
                }
                else {
                    return (
                        <span>
                            {row.officeNumber}
                        </span>
                    );
                }

            }
        },
        {
            field: "department",
            title: "Department",
            customSort: (a, b) => (a.department != null ? a.department.toUpperCase() : "").localeCompare(b.department != null ? b.department.toUpperCase() : ""),
            cellStyle: { minWidth: 195 },
            lookup: filters.departments || {},
            render(row) {
                if (row.isChild) {
                    return (
                       <span title={`Corresponding users merged with ${row.mergedUserId}`}>
                            {row.department}
                        </span>

                    );
                }
                else {
                    return (
                        <span>
                            {row.department}
                        </span>
                    );
                }

            }
        },
        {
            field: "parentGUID",
            title: "Parent GUID",
            cellStyle: { minWidth: 350 },
            customSort: (a, b) => (a.parentGUID != null ? a.parentGUID.toUpperCase() : "").localeCompare(b.parentGUID != null ? b.parentGUID.toUpperCase() : ""),
            lookup: filters.parentGUIDs || {},
            render(row) {
                if (row.isChild) {
                    return (
                       <span title={`Corresponding users merged with ${row.mergedUserId}`}>
                            {row.parentGUID}
                        </span>

                    );
                }
                else {
                    return (
                        <span>
                            {row.parentGUID}
                        </span>
                    );
                }

            }

        },
        {
            field: "childGUID",
            title: "Child GUID",
            cellStyle: { minWidth: 350 },
            customSort: (a, b) => (a.childGUID != null ? a.childGUID.toUpperCase() : "").localeCompare(b.childGUID != null ? b.childGUID.toUpperCase() : ""),
            lookup: filters.childGUIDs || {},
            render(row) {
                if (row.isChild) {
                    return (
                       <span title={`Corresponding users merged with ${row.mergedUserId}`}>
                            {row.childGUID}
                        </span>

                    );
                }
                else {
                    return (
                        <span>
                            {row.childGUID}
                        </span>
                    );
                }

            }
        }
    ];

    const resolveFunction = (resolve, response) => {
        if (response.succeeded) {
       
            resolve({
                data: response.data.users,
                page: response.data.page,
                totalCount: response.data.totalCount,
            });
        } else {
            alert(response.message);
        }
    };
   
    const roleChange = () => {
        approverToStandardUsers();
    }

    async function check() {
        profileMatcherRunningStatus('ProfileMatcher',setstatus, setstarton, setendon);
    }

    //// Write this line

    useEffect(() => {
        check()
    }, []);


    setInterval(check, 10000);
    //const [processing, setProcessing] = useState(false)
   
    return (
        <React.Fragment>
            <Card>
                <ListGroup variant="flush">
                    <ListGroup.Item>
                        <Row>
                            <Col xs={12}
                                md={4}>
                        <ButtonToolbar aria-label="Toolbar with button groups">
                            <ButtonGroup aria-label="First group">
                                <Button disabled={!status} onClick={roleChange} style={{ marginRight: 10 }}>
                                    UAP User Role Validation
                                        </Button>
                                        <Button onClick={showApproverProvisionModal} style={{ marginRight: 10 }} className="ml-2">
                                            User Status Report
                                        </Button>
                            </ButtonGroup>
                                </ButtonToolbar>
                            </Col>
                                <Col
                                style={{ textAlign: "left", fontWeight: "bold" }}
                                xs={12}
                                md={8}
                            >
                                Last Profile Matcher job started on {returnTimeAndDateUTC(starton)} and completed on {returnTimeAndDateUTC(endon)}
                            </Col>
                        </Row>
                    </ListGroup.Item>
                </ListGroup>
            </Card>
           
            <ModalNewModifyUser
                showUserModal={showUserModal}
                setShowUserModal={setShowUserModal}
                isEditingForUser={isEditingForUser}
                fetchAllUsersList={() => {
                    setSelectedFilterItems(setSelectedFilterItems);
                    if (tableRef.current) {
                        tableRef.current.onQueryChange();
                    }
                }}
            />
            <ModalSyncUser
                showUserSyncModal={showUserSyncModal}
                setShowSyncUserModal={setShowSyncUserModal}
                isEditingForUser={isEditingForUser}
                fetchData={() => {
                    setSelectedFilterItems(setSelectedFilterItems);
                    if (tableRef.current) {
                        tableRef.current.onQueryChange();
                    }

                }}
             
            />
            <Row className="">
                <Col xs={12}>
                    <CustomTable
                        columns={columns}
                        tableRef={tableRef}
                        resolveFunction={resolveFunction}
                        title="All User Profiles"
                        showSearchBar={true}
                        fillHeight={true}
                        urlBuilder={urlBuilder}
                        paddingTop={250}
                        getBodyData={getBodyData}
                        fillHeight={true}
                        paddingTop={200}
                        methodType="PUT"
                        isLoading={isLoading}
                        selectedFilterItems={selectedFilterItems}
                        setSelectedFilterItems={setSelectedFilterItems}
                        setFilterExcluded={setFilterExcluded}
                    />
                        
                </Col>
            </Row>
            <Modal
                show={showApproverProvisionerModal}
                size="lg"
                onHide={() => {
                    setShowApproverProvisionerModal(false);
                }}
                dialogClassName="modal-80w"
            >
                <Modal.Body>
                    <Card>
                        <ListGroup variant="flush">
                            <ListGroup.Item>
                                <Row>
                                <Col xs={12}
                                    md={4}>
                                <ButtonToolbar aria-label="Toolbar with button groups">
                                    <ButtonGroup className="mr-2" aria-label="First group">
                                        {selectedPopupRows.length === 0 ? (
                                            <Button
                                                disabled={true}
                                                className="ml-2"
                                            >
                                                Acknowledge Selected Profiles
                                            </Button>
                                        ) : (
                                            <Button
                                                onClick={() => {
                                                    acknowledgeProfiles();
                                                }}
                                                className="ml-2"
                                            >
                                                Acknowledge Selected Profiles
                                            </Button>
                                                )}
                                    </ButtonGroup>
                                        </ButtonToolbar>
                                    </Col>
                                    <Col
                                        style={{ textAlign: "left", fontWeight: "bold" }}
                                        xs={12}
                                        md={8}
                                    >
                                        Internal User Status Report job started on {returnTimeAndDateUTC(statusReportStarton)} and completed on {returnTimeAndDateUTC(statusReportEndon)}
                                    </Col>
                                </Row>
                            </ListGroup.Item>
                        </ListGroup>
                    </Card>
                    <Row className="text-center">
                        <Col xs={12}>
                            <CustomTable
                                data={newPopupFilters ? newPopupDataData : fetchedApproverProvisionerData}
                                columns={columnsApprovers}
                                showSelectionColumn={true}
                                title="Status of Users"
                                onSelectionChange={onPopupTableSelectionChange}
                                showSearchBar={true}
                                fillHeight={true}
                                paddingTop={250}
                                selectedFilterItems={selectedPopupFilterItems}
                                setSelectedFilterItems={setSelectedPopupFilterItems}
                                offlineFilterHelpers={{
                                    originalData: fetchedApproverProvisionerData,
                                    setNewFilters: setNewPopupFilters,
                                    setNewDataFilters: setNewPopupDataFilters,
                                    setNewDataData: setNewPopupDataData,
                                }}
                            />
                            </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        onClick={() => {
                            setShowApproverProvisionerModal(false);
                        }}
                        variant="danger"
                    >
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        </React.Fragment>
    );
};
const mapStateToProps = (state) => (
    {
        loggedUser: state.request.loggedUser,
        fetchedDataForPopup: state.userlist.allusersList
    });

export default connect(mapStateToProps,null)(AllUsersTable);