import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Row, Col, Spinner, Table } from "react-bootstrap";
import { REQUEST_STATUS } from "../../enums";
import { returnTimeAndDateUTC } from "../../globalFunctions";

const ApproveRejectModal = ({
  showModal,
  setShowModal,
  createdBy,
  requestId,
  requestData,
  loadingModal,
  setLoadingModal,
  approveRejectRequest,
  fetchApproversRequestList,
  setShowModifyRequestModal,
    setShowReassignEmailModal
}) => {
    const [comments, setComments] = useState(requestData.approverComment || "");

    useEffect(() => {
        setComments(requestData.approverComment || "");
    }, [requestData.approverComment]);
  const saveComments = (evt) => {
    setComments(evt.target.value);
  };

    const description = requestData.changesRequest;
    
    const getColor = (Name) => {
        if (Name === "Super Administrator" || Name === "Administrator" ) return 'red';
        else
            return 'black';
    };

  const ApproverText =
    requestData.approverRejectedByUser !== null &&
    !requestData.rejectedByApprover
      ? "Approved By"
      : "Rejected By";
  const ProvisionerText =
    requestData.provissionerUser !== null &&
    !requestData.rejectedByApprover &&
    requestData.statusId !== REQUEST_STATUS.COMPLETED
      ? "Provisioning Rejected By"
      : "Provisioned By";

  const haveComments =
    requestData.comments ||
    requestData.approverComment ||
    requestData.provisionerComment
      ? true
      : false;

  return (
    <Modal
      size="lg"
      show={showModal}
      onHide={() => {
        setShowModal(false);
        setLoadingModal(true);
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>Approval Request #{requestId}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!loadingModal ? (
          <React.Fragment>
            <React.Fragment>
              <Row className="text-center pb-2">
                <Col xs={12} className="pt-2 pb-3">
                  <h4>Created By</h4>
                </Col>
                <Col xs={4}>
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    disabled
                    value={
                      requestData.createdByUser.name +
                      " " +
                      requestData.createdByUser.lastName
                    }
                  />
                </Col>
                <Col xs={4}>
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    disabled
                    value={requestData.createdByUser.email || ""}
                  />
                </Col>
                <Col xs={4}>
                  <Form.Label>Creation Date</Form.Label>
                  <Form.Control
                    disabled
                    value={returnTimeAndDateUTC(requestData.createdDate)}
                  />
                </Col>
              </Row>
            </React.Fragment>

            <hr />
            <Row className="text-center pt-2">
              <Col xs={12}>
                <h4>User Information</h4>
              </Col>
              <Col xs={6}>
                <Form.Label>User Name</Form.Label>
                <Form.Control
                  disabled
                  value={
                    requestData.requestUser.name +
                    " " +
                    requestData.requestUser.lastName
                  }
                />
              </Col>
              <Col xs={6}>
                <Form.Label>User Type</Form.Label>
                <Form.Control
                  disabled
                  value={requestData.isInternal ? "Internal" : "External"}
                />
              </Col>
            </Row>
            <Row className="text-center">
              <Col xs={6}>
                <Form.Label>User Primary Email</Form.Label>
                <Form.Control
                  disabled
                  value={requestData.requestUser.email}
                />
              </Col>
              <Col xs={6}>
                <Form.Label>User Platform Email</Form.Label>
                <Form.Control
                  disabled
                  value={requestData.email}
                />
              </Col>
            </Row>
            <Row className="text-center">
              <Col xs={6}>
                <Form.Label>Title</Form.Label>
                <Form.Control
                  disabled
                  value={requestData.requestUser.title || ""}
                />
              </Col>
              <Col xs={6}>
                <Form.Label>User Agency or Organization</Form.Label>
                <Form.Control
                  disabled
                  value={requestData.requestUser.organization || ""}
                />
              </Col>
            </Row>
            <Row className="text-center">
              <Col xs={6}>
                <Form.Label>Department</Form.Label>
                <Form.Control
                  disabled
                  value={requestData.requestUser.department || ""}
                />
              </Col>
                <Col xs={6}>
                    <Form.Label>Phone Number</Form.Label>
                    <Form.Control
                        disabled
                        value={requestData.requestUser.officeNumber || ""}
                    />
                          </Col>
                      </Row>
                          <Row className="text-center">
              <Col xs={12}>
                <Form.Label>Location</Form.Label>
                <Form.Control
                  disabled
                  value={requestData.requestUser.locationCountry || ""}
                />
              </Col>
            </Row>
            <hr />
            <Row className="text-center">
              <Col xs={12} className="pt-2 pb-3">
                <h4>Platform Access Information</h4>
              </Col>
              <Col xs={4}>
                <Form.Label>
                  <h5>Request Type</h5>
                </Form.Label>
                <Form.Control
                  disabled
                  style={{ fontWeight: "bold" }}
                  value={requestData.requestTypeName}
                />
              </Col>
              <Col xs={4}>
                <Form.Label>
                  <h5>Region</h5>
                </Form.Label>
                <Form.Control disabled value={requestData.regionName} />
              </Col>
              <Col xs={4}>
                <Form.Label>
                  <h5>Market</h5>
                </Form.Label>
                <Form.Control disabled value={requestData.marketName} />
              </Col>
            </Row>
            <Row className="text-center pt-1">
              <Col xs={4}>
                <Form.Label>
                  <h5>Agency</h5>
                </Form.Label>
                <Form.Control disabled value={requestData.agencyName} />
              </Col>
              <Col xs={4}>
                <Form.Label>
                  <h5>Practice</h5>
                </Form.Label>
                <Form.Control disabled value={requestData.practiceName} />
              </Col>
              <Col xs={4}>
                <Form.Label>
                  <h5>Platform</h5>
                </Form.Label>
                <Form.Control disabled value={requestData.platformName} />
              </Col>
            </Row>
            <Row className="text-center pt-1">
              <Col xs={4}>
                <Form.Label>
                  <h5>Client</h5>
                </Form.Label>
                <Form.Control disabled value={requestData.clientName} />
              </Col>
              <Col xs={4}>
                <Form.Label>
                  <h5>Client Code</h5>
                </Form.Label>
                <Form.Control disabled value={requestData.clientCodeName} />
              </Col>
              <Col xs={4}>
                <Form.Label>
                  <h5>Account Ownership</h5>
                </Form.Label>
                <Form.Control disabled value={requestData.ownerName} />
              </Col>
            </Row>
            <hr />
            <Row className="pt-3">
              <Col xs={12} className="text-center">
                <h4>Selected Account</h4>
              </Col>
              <Col xs={{ span: 10, offset: 1 }}>
                <Table size="sm" className="table-background-color">
                  <thead>
                <tr>
                     <th>Asset Type</th>
                      <th>Asset Name</th>
                      <th>Asset ID</th>
                      <th>Platform User Type</th>
                      <th>Length of Access</th>
                    </tr>
                  </thead>
                  <tbody>
                <tr>
                      <td>{requestData.assetName}</td>
                      <td style={{ whiteSpace: 'pre-wrap', overflowWrap: 'anywhere' }}>{requestData.clientAccountName}</td>
                      <td>{requestData.assetReference}</td>
                      <td style={{ color: getColor(requestData.platformUserType.basicName) }}>{requestData.platformUserTypeName}</td>
                      <td>
                        {!requestData.isUnlimited ? (
                          <React.Fragment>
                            {new Date(
                              requestData.beginningDate
                            ).toLocaleDateString()}{" "}
                            -{" "}
                            {new Date(
                              requestData.expirationDate
                            ).toLocaleDateString()}
                          </React.Fragment>
                        ) : (
                          "Unlimited"
                        )}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </Row>
            <hr />

            {haveComments ? (
              <React.Fragment>
                <Row>
                  <Col xs={12} className="text-center">
                    <h4>Comments</h4>
                  </Col>
                  <Col xs={{ span: 10, offset: 1 }}>
                    <Table size="sm" className="table-background-color">
                      <thead>
                        <tr>
                          <th>From</th>
                          <th>Comments</th>
                        </tr>
                      </thead>
                      <tbody>
                        {requestData.comments ? (
                          <tr>
                            <td>Requestor</td>
                                                      <td>{requestData.comments}</td>
                          </tr>
                        ) : null}
                        {requestData.approverComment ? (
                          <tr>
                            <td>Approver</td>
                            <td>{requestData.approverComment}</td>
                          </tr>
                        ) : null}
                        {requestData.provisionerComment ? (
                          <tr>
                            <td>Provisioner</td>
                            <td>{requestData.provisionerComment}</td>
                          </tr>
                        ) : null}
                      </tbody>
                    </Table>
                  </Col>
                </Row>
                <hr />
              </React.Fragment>
            ) : null}

            {requestData.changesRequest !== null &&
            requestData.changesRequest !== "" ? (
              <React.Fragment>
                <hr />
                <Row className="text-center">
                  <Col xs={12} sm={{ span: 8, offset: 2 }}>
                    <Form.Label>
                      <h4>Changes</h4>
                    </Form.Label>
                  </Col>
                  <Col xs={{ span: 10, offset: 1 }}>
                    <Table size="sm" className="table-background-color">
                      <tbody>
                        <tr>
                          <td>
                            <b>Description</b>
                          </td>
                          <td>{requestData.changesRequest}</td>
                        </tr>
                      </tbody>
                    </Table>
                  </Col>
                </Row>
                <hr />
              </React.Fragment>
            ) : null}
            {requestData.approverRejectedByUser !== null ? (
              <React.Fragment>
                <Row className="text-center">
                  <Col xs={12} sm={{ span: 8, offset: 2 }}>
                    <Form.Label>
                      <h4>{ApproverText}</h4>
                    </Form.Label>
                  </Col>
                </Row>
                <Row>
                  <Col xs={4} className="text-center">
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      disabled
                      value={
                        requestData.approverRejectedByUser.name +
                        " " +
                        requestData.approverRejectedByUser.lastName
                      }
                    />
                  </Col>
                  <Col xs={5} className="text-center">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      disabled
                      value={requestData.approverRejectedByUser.email}
                    />
                  </Col>
                  <Col xs={3} className="text-center">
                    <Form.Label>Date</Form.Label>
                    <Form.Control
                      disabled
                      value={new Date(
                        requestData.approvedDate
                      ).toLocaleDateString()}
                      className="text-center"
                    />
                  </Col>
                </Row>
                <hr />
              </React.Fragment>
            ) : null}
            {requestData.provissionerUser !== null ? (
              <React.Fragment>
                <Row className="text-center">
                  <Col xs={12} sm={{ span: 8, offset: 2 }}>
                    <Form.Label>
                      <h4>{ProvisionerText}</h4>
                    </Form.Label>
                  </Col>
                </Row>
                <Row>
                  <Col xs={4} className="text-center">
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      disabled
                      value={
                        requestData.provissionerUser.name +
                        " " +
                        requestData.provissionerUser.lastName
                      }
                    />
                  </Col>
                  <Col xs={5} className="text-center">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      disabled
                      value={requestData.provissionerUser.email}
                    />
                  </Col>
                  <Col xs={3} className="text-center">
                    <Form.Label>Date</Form.Label>
                    <Form.Control
                      disabled
                      value={new Date(
                        requestData.provissionedDate
                      ).toLocaleDateString()}
                      className="text-center"
                    />
                  </Col>
                </Row>
                <hr />
              </React.Fragment>
            ) : null}
            {requestData.statusId === REQUEST_STATUS.PENDING ? (
              <Form.Group>
                <Form.Label>
                <h5>Leave a comment <b style={{ color: "red" }}>*</b></h5>
                </Form.Label>
                <Form.Control
                  as="textarea"
                  rows="3"
                  defaultValue={requestData.approverComment}
                  onChange={saveComments}
                  maxLength={500}
                />
              </Form.Group>
            ) : null}
          </React.Fragment>
        ) : (
          <Row className="text-center">
            <Col xs={12}>
              <Spinner animation="border" size="lg" />
            </Col>
          </Row>
        )}
      </Modal.Body>
      {requestData.statusId === REQUEST_STATUS.PENDING && !loadingModal ? (
        <Modal.Footer>
          <Row className="text-center">
            <Button
              className="ml-2"
              variant="success"
                          onClick={() => {
                              if (comments.replace(/\s/g, "").length) {
                                setLoadingModal(true);
                                approveRejectRequest(
                                    true,
                                    comments,
                                    createdBy,
                                    requestId,
                                    fetchApproversRequestList,
                                    setShowModal,
                                    description
                                );
                            }
                            else {
                                alert("You have to input a comment.")
                              }
              }}
            >
              Approve
            </Button>
            {requestData.requestTypeId !== 2 ? (
              <Button
                className="ml-2"
                variant="warning"
                onClick={() => {
                    if (requestData.isReassignedRequest && requestData.reassignedPrimaryEmail != null && requestData.reassignedPrimaryEmail != "" && requestData.reassignedPrimaryEmail != undefined) {
                        setShowReassignEmailModal(true);
                        setShowModal(false);

                    }
                    else {
                        setShowModifyRequestModal(true);
                        setShowModal(false);
                    }
                 
                }}
              >
                Modify
              </Button>
                      ) : null}
                      {requestData.requestTypeId == 2 && !requestData.isUnlimited ? (
                          <Button
                              className="ml-2"
                              variant="danger"
                              onClick={() => {
                                  alert("You cannot reject a deactivation request for an expired access. Please modify the length of access to extend to a future date and then approve the request.");
                                  setShowModifyRequestModal(true);
                                  setShowModal(false);
                              }}
                          >
                              Reject
                          </Button> 
                      ): (<Button
                              className = "ml-2"
                              variant = "danger"
                              onClick = {
                          () => {
                              if(!comments.replace(/\s/g, "").length) {
                                    alert("You have to input a comment.");
                              }
                              else if (requestData.requestTypeId !=2)
                              {
                                  var result = window.confirm("Press OK to proceed with rejection or hit cancel to return to task and modify it to meet appropriateness for approval such as changing the requested user role.");
                                  if (result) {
                                      setLoadingModal(true);
                                      approveRejectRequest(
                                          false,
                                          comments,
                                          createdBy,
                                          requestId,
                                          fetchApproversRequestList,
                                          setShowModal,
                                          description
                                      );
                                  }
                                 
                              }
                              else
                              {
                                  setLoadingModal(true);
                                  approveRejectRequest(
                                      false,
                                      comments,
                                      createdBy,
                                      requestId,
                                      fetchApproversRequestList,
                                      setShowModal,
                                      description
                                  );
                              }
                              }}
                          >
                      Reject
                  </Button>
                  )}
            
          </Row>
        </Modal.Footer>
      ) : null}
    </Modal>
  );
};

export default ApproveRejectModal;
